import SavedLocation from "./SavedLocation";
import { SavedLocationSearchOption } from "./SearchOption";

const mapSavedLocationToSearchOption = (
  location: SavedLocation
): SavedLocationSearchOption => {
  return {
    type: "SAVED_LOCATION",
    value: {
      id: location.id,
      locationType: location.type,
      data: location,
    },
  };
};

export default mapSavedLocationToSearchOption;
