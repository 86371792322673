import { StopNew } from "../../../ducks/data/route-with-stops/types";
import TodayIcon from "@mui/icons-material/Today";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Button from "@mui/material/Button";
import "./stop-component.scss";
import { useState } from "react";
import EditDateDialog from "../../SessionsPage/StopPage/edit-dialogs/EditDateDialog";
import EditTimeDialog from "../../SessionsPage/StopPage/edit-dialogs/EditTimeDialog";
import { DateTime } from "luxon";
import { useRoutePageContext } from "../RoutePageContext";

const StopDateAndTime = ({ stop }: { stop: StopNew }) => {
  const { t } = useTranslation(["translation"]);
  const [shouldShowEditDateDialog, setShouldShowEditDateDialog] =
    useState(false);
  const [shouldShowEditTimeDialog, setShouldShowEditTimeDialog] =
    useState(false);

  const { reloadData } = useRoutePageContext();

  return (
    <>
      {shouldShowEditDateDialog && (
        <EditDateDialog
          stopId={stop.id}
          date={DateTime.fromFormat(stop.date, "yyyy-MM-dd")}
          cargoId={stop.cargoId}
          onClose={() => {
            reloadData();
            setShouldShowEditDateDialog(false);
          }}
        />
      )}
      {shouldShowEditTimeDialog && (
        <EditTimeDialog
          onClose={() => {
            reloadData();
            setShouldShowEditTimeDialog(false)
          }}
          time={stop.time}
          cargoId={stop.cargoId}
          stopId={stop.id}
        />
      )}
      <div className="stop-component">
        <TodayIcon className="stop-component-icon" />
        <div className="flex-grow">
          <div className="flex justify-between items-center">
            <div className="flex-grow">{stop.date}</div>
            <Button onClick={() => setShouldShowEditDateDialog(true)}>
              <EditOutlinedIcon />
            </Button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex-grow">
              <>{stop.time && ` ${stop.time}`} </>
              <>{stop.time && stop.fixTime && ` (${t("translation:Fix")})`}</>
            </div>
            <Button onClick={() => setShouldShowEditTimeDialog(true)}>
              <EditOutlinedIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StopDateAndTime;
