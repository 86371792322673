import { useTranslation } from "react-i18next";

const RouteAddress = (props: {
  address: {
    address: string | null;
    postcode: string | null;
    city: string | null;
    country: string | null;
    placeName: string | null;
  };
}) => {
  const { address, postcode, city, country, placeName } = props.address;
  const { t } = useTranslation("translation");
  if (!city || !country) {
    return <>{t("missingAddress")}</>;
  }
  const cityLine = postcode
    ? city
      ? `${country}-${postcode} ${city}`
      : `${country}-${postcode}`
    : `${city}, ${country}`;
  return (
    <>
      {placeName && placeName !== address && (
        <>
          {placeName}
          <br />
        </>
      )}
      {address && (
        <>
          {address}
          <br />
        </>
      )}
      {cityLine}
    </>
  );
};

export default RouteAddress;
