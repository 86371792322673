import { StopNew } from "../../../ducks/data/route-with-stops/types";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { loadStopDocuments } from "../../../ducks/app/session";
import { useAppDispatch } from "../../../redux-store";
import { useSelector } from "react-redux";
import { selectStopDocuments } from "../../../ducks/app/session/selectors";
import { Link as MUILink } from "@mui/material";
import "./stop-component.scss";

const StopDocuments = ({ stop }: { stop: StopNew }) => {
  const { t } = useTranslation(["translation"]);
  const [loadingState, setLoadingState] = useState<
    "idle" | "loading" | "error"
  >("idle");
  const dispatch = useAppDispatch();

  const files = useSelector(selectStopDocuments(stop.id));

  useEffect(() => {
    setLoadingState("loading");
    dispatch(loadStopDocuments({ stopId: stop.id }))
      .then(() => {
        setLoadingState("idle");
      })
      .catch((err) => {
        console.error(err);
        setLoadingState("error");
      });
  }, [dispatch, stop]);

  if (!files.length) {
    return null;
  }

  return (
    <>
      <div className="stop-component">
        <AttachFileIcon className="stop-component-icon" />
        <div className="flex-grow">
          {loadingState === "loading" && t("translation:LoadingFiles")}
          {loadingState === "error" && t("translation:ErrorLoadingFiles")}
          {loadingState === "idle" &&
            files.length > 0 &&
            files.map((file) => (
              <div key={file.id}>
                <MUILink href={file.url} target="_blank" rel="noreferrer">
                  {file.name}
                </MUILink>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default StopDocuments;
