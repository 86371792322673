import { Stop } from "../../../ducks/app/session/types";
import { useState } from "react";
import AddCommentModal from "./AddCommentModal";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

const AddCommentModalAndButton = (input: {
  sessionId: string;
  stop: Stop;
  comments: any[];
}) => {
  const { stop, sessionId, comments } = input;
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const { t } = useTranslation(["stopPage"]);

  return (
    <>
      {showAddNoteDialog && (
        <AddCommentModal
          sessionId={sessionId!}
          stop={stop}
          onClose={() => setShowAddNoteDialog(false)}
        />
      )}
      <Button
        onClick={() => setShowAddNoteDialog(true)}
        variant="outlined"
        size="large"
        startIcon={<EditNoteOutlinedIcon />}
      >
        {t("stopPage:addNote")}
        {comments.length ? <CheckIcon /> : null}
      </Button>
    </>
  );
};

export default AddCommentModalAndButton;
