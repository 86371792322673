import Stack from "@mui/material/Stack";
import { Waypoint } from "../../ducks/app/session/types";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import RouteAddress from "./RouteAddress";

const WaypointPage = ({ waypoint }: { waypoint: Waypoint }) => {
  const { t } = useTranslation(["translation", "stopPage", "types"]);
  const googleMapsQuery = encodeURIComponent(
    `${waypoint.place.coord?.lat},${waypoint.place.coord?.lon}`
  );
  return (
    <Stack>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            disableRipple
            sx={{ px: 0 }}
            aria-label={t("stopPage:showOnMap")}
            component="a"
            href={`https://www.google.com/maps/search/?api=1&query=${googleMapsQuery}`}
            target="_blank"
            rel="noreferrer"
          >
            <ListItemIcon>
              <LocationOnOutlinedIcon />
            </ListItemIcon>
            <RouteAddress address={waypoint.place} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton disableRipple disableTouchRipple sx={{ px: 0 }}>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            {waypoint.note}
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );
};

export default WaypointPage;
