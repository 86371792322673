import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as selectors from "./selectors";
import * as actions from ".";
import { useAppDispatch } from "../../../redux-store";

export const useRouteLogin = ({
  showCompletedTrips,
}: {
  showCompletedTrips?: boolean;
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.init());
    const loadTrailersInterval = setInterval(() => {
      dispatch(actions.init());
    }, 5000);
    return () => clearInterval(loadTrailersInterval!);
  }, [dispatch]);
  const status = useSelector(selectors.selectStatus);
  const trailers = useSelector(selectors.selectTrailers);
  const routes = useSelector(
    showCompletedTrips
      ? selectors.selectEndedRoutes
      : selectors.selectNotEndedRoutes
  );
  const loginRoute = (input: { routeId: string | null; trailerId: string }) => {
    return dispatch(actions.loginRoute(input)).unwrap();
  };

  return { status, trailers, routes, loginRoute };
};
