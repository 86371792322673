import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { clientsT, clientT } from "dora-contracts";
import * as t from "io-ts";
// import { createDecoder } from "io-ts-promise";
import api, { createDecoder } from "../../api";

type Client = t.TypeOf<typeof clientT>;

const prefix = "data/clients";

type State = { clients?: Client[] };

const initialState: State = {};

export const loadClients = createAsyncThunk(
  `${prefix}/load-clients`,
  async (_: void, thunkApi) => {
    return await api(thunkApi).get({
      url: "/drivers-api/clients",
      decoder: createDecoder(clientsT),
    });
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadClients.fulfilled, (state, action) => {
      state.clients = action.payload;
    });
  },
});

export default slice.reducer;
