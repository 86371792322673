import { useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getPosition } from "../../../helpers/position";
import { useGetLocations, usePlaceSearch } from "./hooks";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import {
  loadSavedLocations,
  actions,
} from "../../../ducks/data/saved-locations";
import {
  selectFavoriteLocations,
  selectTerminalLocations,
} from "../../../ducks/data/saved-locations/selectors";
import SearchResultOption from "./SearchResultOption";
import { reportErrorToBackend } from "../../../ducks/api";
import mapSavedLocationToSearchOption from "./map-saved-location-to-search-option";
import { SearchOption } from "./SearchOption";
import { distanceFromPoint } from "./use-sort-by-closeness-to-driver-location";
import { SavedLocations } from "../../../ducks/data/saved-locations/types";
import sortBy from "lodash/sortBy";

const defaultSearchLocation = { lat: 48.2082, lon: 16.3738 };

interface PlaceSearchProps {
  label: string;
  location: SearchOption | null;
  onLocationChange: (loc: null | SearchOption) => void;
}

const getOptionLabel = (option: SearchOption) => {
  switch (option.type) {
    case "SEARCH_RESULT":
      return option.value.data.title.label;
    case "SAVED_LOCATION":
      return option.value.data.customName;
    case "REVERSE_GEOCODE_OPTION":
      return option.value.data.label;
    default:
      return "";
  }
};

export type InnerPlaceSearchProps = PlaceSearchProps & {
  savedFavorites: SavedLocations;
  savedTerminals: SavedLocations;
};

export const InnerPlaceSearch = ({
  label,
  location,
  onLocationChange,
  savedFavorites,
  savedTerminals,
}: InnerPlaceSearchProps) => {
  const [dirty, setDirty] = useState(false);
  const [searchState, setSearchState] = useState<{
    inputValue: string;
    results: SearchOption[];
  }>({ inputValue: "", results: [] });
  const { inputValue, results } = searchState;
  const [startLocations, setStartLocations] = useState<SearchOption[]>([]);

  useEffect(() => {
    const inputValue = location ? getOptionLabel(location) : "";
    setSearchState((state) => ({
      inputValue,
      results: inputValue === state.inputValue ? state.results : [],
    }));
  }, [location]);

  const onInputChange = (e: any, value: string) => {
    const inputValue = value.trim();
    setSearchState((state) => {
      const results = inputValue ? state.results : [];
      return { inputValue, results };
    });
    //
    if (inputValue && e) {
      setDirty(true);
      suggest(
        inputValue,
        {
          type: "AT",
          position: searchLocation,
        },
        (err, data) => {
          if (err) {
            reportErrorToBackend({ err });
            return;
          } else {
            setSearchState((currentState) => {
              if (currentState.inputValue !== inputValue) {
                return currentState;
              }
              return {
                inputValue,
                results: (data || []).map((x) => ({
                  type: "SEARCH_RESULT",
                  id: x.id,
                  value: { id: x.id, data: x, locationType: "SEARCH_RESULT" },
                })),
              };
            });
          }
        }
      );
    }
  };

  const [searchLocation, setSearchLocation] = useState<{
    lat: number;
    lon: number;
  }>(defaultSearchLocation);
  const suggest = usePlaceSearch();

  const lowerCaseMatch = (input: string, value: string) =>
    value.toLowerCase().includes(input.toLowerCase());

  const [filteredSavedTerminals, filteredSavedFavorites] = useMemo(() => {
    if (!inputValue.trim()) {
      return [
        sortBy(
          savedTerminals.map(mapSavedLocationToSearchOption),
          distanceFromPoint(searchLocation)
        ).slice(0, 3),
        sortBy(
          savedFavorites.map(mapSavedLocationToSearchOption),
          distanceFromPoint(searchLocation)
        ).slice(0, 3),
      ];
    } else {
      return [
        sortBy(
          savedTerminals
            .filter((t) => lowerCaseMatch(inputValue, t.customName))
            .map(mapSavedLocationToSearchOption),
          distanceFromPoint(searchLocation)
        ),
        sortBy(
          savedFavorites
            .filter((t) => lowerCaseMatch(inputValue, t.customName))
            .map(mapSavedLocationToSearchOption),
          distanceFromPoint(searchLocation)
        ),
      ];
    }
  }, [savedTerminals, savedFavorites, inputValue, searchLocation]);

  const onChange = async (_: unknown, newValue: SearchOption | null) => {
    onLocationChange(newValue);
  };

  useEffect(() => {
    getPosition().then((loc) => {
      if (loc) {
        setSearchLocation(loc);
      } else {
        setSearchLocation(defaultSearchLocation);
      }
    });
  }, [setSearchLocation]);

  // Don't know if there's a better way. If the parent component does not use
  // useCallback to stabilise the onLocationChange event handler (and we
  // correctly add it as a dependency to the useEffect hook), then the result
  // would be that we would trigger a new reverse geocode call on every
  // rerender.
  const onLocationChangeRef = useRef<typeof onLocationChange>(onLocationChange);
  onLocationChangeRef.current = onLocationChange;

  const getLocations = useGetLocations();
  useEffect(() => {
    getLocations().then(async (locations) => {
      if (locations.length) {
        const mappedLocations = locations.map(
          (location): SearchOption => ({
            type: "REVERSE_GEOCODE_OPTION",
            value: {
              id: location.id,
              locationType: "REVERSE_GEOCODE_OPTION",
              data: location,
            },
          })
        );
        setStartLocations(mappedLocations);
        onLocationChangeRef.current(mappedLocations[0]);
      }
    });
  }, [getLocations, onLocationChangeRef]);

  const allOptions = useMemo(
    () => [
      ...filteredSavedTerminals.slice(0, 2),
      ...filteredSavedFavorites.slice(0, 2),
      ...(!dirty ? startLocations : results),
    ],
    [
      dirty,
      filteredSavedTerminals,
      filteredSavedFavorites,
      results,
      startLocations,
    ]
  );

  const [options, filterSelectedOptions] =
    // If _something_ is selected, and the selected value is not part of the
    // current values, add it, but hide it (as the currently selected value
    // MUST be an element in this list)
    location && !allOptions.includes(location)
      ? [[location, ...allOptions], true]
      : [allOptions, false];

  return (
    <>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        options={options}
        filterOptions={(x) => x}
        autoSelect
        selectOnFocus
        clearOnBlur
        clearOnEscape
        autoHighlight
        filterSelectedOptions={filterSelectedOptions}
        includeInputInList
        value={location}
        onChange={onChange}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            name="address"
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "disabled",
            }}
            label={label}
            fullWidth
            type="text"
            margin="dense"
          />
        )}
        renderOption={(p, o) => (
          <li {...p} key={o.value.id}>
            <SearchResultOption option={o} />
          </li>
        )}
      />
    </>
  );
};

const PlaceSearch = (props: PlaceSearchProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadSavedLocations());
    return () => {
      dispatch(actions.clearSavedLocations());
    };
  }, [dispatch]);
  const savedTerminals = useAppSelector(selectTerminalLocations);
  const savedFavorites = useAppSelector(selectFavoriteLocations);
  return (
    <InnerPlaceSearch
      {...props}
      savedTerminals={savedTerminals}
      savedFavorites={savedFavorites}
    />
  );
};

export default PlaceSearch;
