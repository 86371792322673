import { StopNew } from "../../../ducks/data/route-with-stops/types";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./stop-component.scss";

const StopLoadingRefContact = ({ stop }: { stop: StopNew }) => {
  const { t } = useTranslation(["translation"]);

  if (!stop.ref && !stop.contact) {
    return null;
  }

  return (
    <>
      <div className="stop-component">
        <InfoOutlinedIcon className="stop-component-icon" />
        <div className="flex-grow">
          <div>{stop.ref && `${t("translation:Reference")}: ${stop.ref}`}</div>
          <div>
            {stop.contact && `${t("translation:Contact")}: ${stop.contact}`}
          </div>
        </div>
      </div>
    </>
  );
};

export default StopLoadingRefContact;
