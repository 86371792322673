import { RouteWaypointStopNew } from "../../../ducks/data/route-with-stops/types";
import { useMemo } from "react";
import "./DispatcherCreatedStop.scss";

const DispatcherCreatedWaypointStop = ({
  waypointStop,
}: {
  waypointStop: RouteWaypointStopNew;
}) => {
  const waypointPlace = useMemo(() => {
    const placeComponents = [
      waypointStop.waypoint.place.placeName,
      waypointStop.waypoint.place.city,
      waypointStop.waypoint.place.postcode,
    ];
    return placeComponents.filter(Boolean).join(", ");
  }, [waypointStop]);

  return (
    <div className="dispatcher-created-stop">
      <div className="dispatcher-created-stop__header">{waypointPlace}</div>
    </div>
  );
};

export default DispatcherCreatedWaypointStop;
