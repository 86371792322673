import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../../../redux-store";
import { updateCargoStop } from "../../../../ducks/app/update-cargo";

type EditTimeDialogProps = {
  onClose: () => void;
  time: string | null;
  cargoId: string;
  stopId: string;
};

const EditTimeDialog = (props: EditTimeDialogProps) => {
  const { cargoId, stopId, onClose } = props;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [time, setTime] = useState<string | null>(props.time);

  const onSaveButtonClick = async () => {
    try {
      setIsSaving(true);
      await dispatch(
        updateCargoStop({ cargoId, stopId, data: { time: time! } })
      );
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  return (
    <Dialog fullWidth open onClose={() => !isSaving && onClose()}>
      <DialogTitle>{t("editDialogs.timeTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="dense"
          type="time"
          value={time}
          onChange={(e) => {
            const time = DateTime.fromFormat(e.target.value, "HH:mm");
            setTime(time.toFormat("HH:mm"));
          }}
        />
      </DialogContent>
      <DialogActions className="mx-4 mb-2">
        <Button
          disabled={isSaving}
          onClick={onClose}
          variant="outlined"
          size="large"
          fullWidth
        >
          {t("editDialogs.cancelButton")}
        </Button>
        <Button
          disabled={isSaving || time === props.time}
          onClick={onSaveButtonClick}
          variant="contained"
          fullWidth
          size="large"
        >
          {t("editDialogs.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTimeDialog;
