import take from "lodash/take";
import { AutoSuggestItem } from "dora-shared";
import { reverseGeocode } from "../helpers/reverse-geocoder";

export const reverseGeoCodeMany = async (coord: {
  lat: number;
  lon: number;
}): Promise<AutoSuggestItem[]> => {
  const response = await reverseGeocode(coord);
  return take(response, 3).map((result) => {
    return {
      id: result.googlePlaceId,
      label: result.formattedAddress || "",
      place: result.formattedAddress,
      lookup: () =>
        Promise.resolve({
          placeName: "",
          countryCode: result.country,
          coords: result.coords,
          address: result.address,
          city: result.city,
          postcode: result.postcode,
        }),
      title: {
        label: "",
        highlights: [],
      },
      address: {
        label: result.address,
        highlights: [],
      },
    };
  });
};

export const reverseGeoCode = async (coord: { lat: number; lon: number }) => {
  const results = await reverseGeoCodeMany(coord);
  return results.length > 0 ? results[0] : null;
};
