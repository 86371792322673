import { useTranslation } from "react-i18next";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";

type ActivityTimeEditDialogProps = {
  onClose: () => void;
  onDateSelected: (date: DateTime) => void;
  initialValue: DateTime;
  title: string;
  actionInProgress: boolean;
};

const ActivityTimeEditDialog = (props: ActivityTimeEditDialogProps) => {
  const { t } = useTranslation("stopPage");
  const { title, onClose, actionInProgress, onDateSelected } = props;

  const [date, setDate] = useState(props.initialValue);

  const onOkClick = () => {
    if (date) {
      onDateSelected(date);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-1.5 mt-2">
          <div>{t("arrivalDeparture.date")}</div>
          <TextField
            fullWidth
            type="date"
            margin="dense"
            value={date.toISODate()}
            onChange={(e) => {
              const eventDate = DateTime.fromISO(e.target.value);
              setDate(
                date!.set({
                  day: eventDate.day,
                  month: eventDate.month,
                  year: eventDate.year,
                })
              );
            }}
          />
          <div>{t("arrivalDeparture.time")}</div>
          <TextField
            type="time"
            fullWidth
            value={date?.toFormat("HH:mm")}
            onChange={(e) => {
              const time = DateTime.fromFormat(e.target.value, "HH:mm");
              setDate(
                date!.set({
                  hour: time.get("hour"),
                  minute: time.get("minute"),
                })
              );
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className="mx-4 mb-2">
        <Button
          disabled={actionInProgress}
          onClick={onClose}
          variant="outlined"
          size="large"
          fullWidth
        >
          {t("arrivalDeparture.cancel")}
        </Button>
        <Button
          variant="contained"
          fullWidth
          size="large"
          disabled={actionInProgress}
          onClick={onOkClick}
        >
          {t("arrivalDeparture.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivityTimeEditDialog;
