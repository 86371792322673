import { StopNew } from "../../../ducks/data/route-with-stops/types";
import { useSelector } from "react-redux";
import {
  StopDriverArrived,
  StopDriverDeparted,
} from "../../SessionsPage/StopPage/StopDriverArrivedDeparted";
import { useParams } from "../../SessionsPage/hooks";
import { PickupDropoffButton } from "../../SessionsPage/StopPage/StopCompleted";
import AddImage from "../../SessionsPage/StopPage/AddImage";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import {
  selectImagesAddedOnCargo,
  selectShouldShowArrivalDepartureButtonsNew,
} from "../../../ducks/data/route-with-stops/selectors";
import { useState } from "react";
import AddDropoffDialog from "../../SessionsPage/AddDropoffDialog";
import AddWeightModalAndButton from "../../SessionsPage/modals/AddWeightModalAndButton";
import AddCubicMetersModalAndButton from "../../SessionsPage/modals/AddCubicMetersModalAndButton";
import AddRefModalAndButton from "../../SessionsPage/modals/AddRefModalAndButton";
import AddCommentModalAndButton from "../../SessionsPage/modals/AddCommentModalAndButton";
import { useRoutePageContext } from "../RoutePageContext";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { selectShouldShowCompleteStopButton } from "../../../ducks/auth/selectors";

const StopButtons = ({
  stop,
  cargoId,
  cargoInvoiceRef,
  hideAddDropoffButton,
  onAddDropoff,
}: {
  stop: StopNew;
  cargoId: string;
  cargoInvoiceRef: string | null;
  hideAddDropoffButton?: boolean;
  onAddDropoff: (stopId: string) => void;
}) => {
  const { sessionId } = useParams();
  const [showAddDropoff, setShowAddDropoff] = useState(false);

  const { t } = useTranslation(["stopPage"]);

  const shouldShowArrivalDepartureButtons = useSelector(
    selectShouldShowArrivalDepartureButtonsNew(stop.id)
  );
  const shouldShowCompleteStopButton = useSelector(
    selectShouldShowCompleteStopButton
  );
  const imagesAddedOnStopCargo = useSelector(selectImagesAddedOnCargo(cargoId));

  const { reloadData } = useRoutePageContext();

  const stopWithCargoId = {
    ...stop,
    cargo_id: stop.cargoId,
  };

  const podNumberForStop = stop.attachments.filter(
    (a) => a.documentType === "POD"
  ).length;

  return (
    <div className="flex flex-col gap-2 mb-3">
      {showAddDropoff && (
        <AddDropoffDialog
          skipCompleteEvent
          sessionId={sessionId!}
          cargoId={stop.cargoId}
          onClose={() => setShowAddDropoff(false)}
          onSuccess={(stopId) => {
            setShowAddDropoff(false);
            reloadData();
            onAddDropoff(stopId);
          }}
        />
      )}
      {shouldShowArrivalDepartureButtons && (
        <div className="flex gap-2 items-center justify-between">
          <StopDriverArrived
            sessionId={sessionId as string}
            stop={stopWithCargoId}
          />
          <StopDriverDeparted
            sessionId={sessionId as string}
            stop={stopWithCargoId}
          />
        </div>
      )}
      {shouldShowCompleteStopButton && (
        <PickupDropoffButton
          stop={stopWithCargoId}
          sessionId={sessionId || ""}
        />
      )}
      <AddImage
        sessionId={sessionId as string}
        cargoId={stop.cargoId}
        stopId={stop.id}
        added={imagesAddedOnStopCargo}
        imagesForStop={podNumberForStop}
        onSuccess={reloadData}
      />

      <AddCommentModalAndButton
        stop={{
          ...stop,
          cargo_id: stop.cargoId,
        }}
        sessionId={sessionId!}
        comments={stop.comments}
      />
      <AddWeightModalAndButton
        stop={{
          ...stop,
          cargo_id: stop.cargoId,
        }}
        weightAdded={stop.weightAdded}
        sessionId={sessionId!}
      />
      <AddCubicMetersModalAndButton
        sessionId={sessionId!}
        stop={{
          ...stop,
          cargo_id: stop.cargoId,
        }}
        volumeAdded={stop.volumeAdded}
      />
      <AddRefModalAndButton
        sessionId={sessionId!}
        stop={{
          ...stop,
          cargo_id: stop.cargoId,
        }}
        cargoInvoiceRef={cargoInvoiceRef}
      />
      {!hideAddDropoffButton && (
        <>
          <div>{t("stopPage:addToOrder")}:</div>
          <Button
            onClick={() => setShowAddDropoff(true)}
            style={{
              background: "var(--warning-500)",
            }}
            variant="contained"
            size="large"
          >
            <div className="flex gap-2 items-center">
              <AddLocationAltOutlinedIcon />
              <span>{t("stopPage:addDropoff")}</span>
            </div>
          </Button>
        </>
      )}
    </div>
  );
};

export default StopButtons;
