import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";
import sortBy from "lodash/sortBy";

export const selectClients = createSelector(
  (state: RootState) => state.data.clients.clients,
  (clients) => sortBy(clients, "name")
);

export const selectClient = (id: string | null) =>
  createSelector(selectClients, (clients) => clients.find((c) => c.id === id));
