import { RootState } from "../../../redux-store";
import { isNotNull, isNotNullOrUndefined } from "dora-shared";
import { RouteCargoStopNew } from "./types";
import {
  selectMe,
  selectShouldShowCompleteStopButton,
} from "../../auth/selectors";
import { createSelector } from "@reduxjs/toolkit";
import { selectClient } from "../clients/selectors";
import { DateTime } from "luxon";
import { ExpandedRow } from "./index";

const selectRouteWithStops = (state: RootState) => state.data.routeWithStops;

export const selectIsRowExpanded = (identifier: ExpandedRow) =>
  createSelector(
    selectRouteWithStops,
    (data) =>
      data.expandedRow?.id === identifier.id &&
      data.expandedRow.type === identifier.type
  );

export const selectCurrentTruckId = createSelector(
  selectRouteWithStops,
  (routeWithStops) => routeWithStops.selectedTrailerId || ""
);

export const selectRouteWithStopsData = createSelector(
  selectRouteWithStops,
  (routeWithStops) => {
    return routeWithStops.data;
  }
);

export const selectCurrentRouteId = createSelector(
  selectRouteWithStopsData,
  (routeData) => routeData?.routeId
);

export const selectStopsAndWaypointsNumberNew = createSelector(
  selectRouteWithStopsData,
  (routeData) => routeData?.routeStops.length || 0
);

export const selectCargoTimeTracking = (cargoId: string) =>
  createSelector(selectCargoNew(cargoId), (cargo) => {
    if (!cargo) {
      return null;
    }
    return cargo.timeTracking;
  });

export const selectShowCompletedDispatcherStops = (state: RootState) =>
  state.data.routeWithStops.showCompletedDispatcherCreatedStops;

export const showHideCompletedStopsButtonNew = createSelector(
  selectRouteWithStopsData,
  (routeData) => {
    if (!routeData) {
      return false;
    }
    return routeData.routeStops.some((rs) => {
      if (
        rs.type === "CARGO_STOP" &&
        (rs.stop.completedAt || rs.stop.departedAt)
      ) {
        const cargoForStop = routeData.cargos.find((c) =>
          c.stops.includes(rs.stop.id)
        )!;
        return cargoForStop.createdBy === "DISPATCHER";
      }
      return false;
    });
  }
);

export const selectStopsCreatedByDispatcher = createSelector(
  selectRouteWithStopsData,
  (routeData) => {
    if (!routeData) {
      return null;
    }

    const cargosCreatedByDispatcher = routeData.cargos.filter(
      (c) => c.createdBy === "DISPATCHER"
    );

    const cargoStopsCreatedByDispatcher = cargosCreatedByDispatcher.flatMap(
      (c) => c.stops
    );

    const routeStopsInOrderCreatedByDispatcher = routeData.routeStops.filter(
      (rs) =>
        rs.type === "WAYPOINT" ||
        cargoStopsCreatedByDispatcher.find((s) => s === rs.stop.id)
    );

    return routeStopsInOrderCreatedByDispatcher
      .map((rs) => {
        if (rs.type === "WAYPOINT") {
          return rs;
        } else {
          const routeStop = routeData.routeStops.find(
            (rss) => rss.type === "CARGO_STOP" && rss.stop.id === rs.stop.id
          ) as RouteCargoStopNew;
          if (!routeStop) {
            return null;
          }
          return {
            type: "CARGO_STOP" as const,
            stop: routeStop.stop,
          };
        }
      })
      .filter(isNotNullOrUndefined);
  }
);

export const selectCompletedStopsCreatedByDispatcher = createSelector(
  selectStopsCreatedByDispatcher,
  selectShouldShowCompleteStopButton,
  (stops, shouldShowCompleteStopButton) => {
    if (!stops) {
      return null;
    }
    const result = [];
    for (const s of stops) {
      if (s.type === "WAYPOINT") {
        result.push(s);
      } else {
        if (
          s.type === "CARGO_STOP" &&
          (shouldShowCompleteStopButton
            ? s.stop.completedAt
            : s.stop.departedAt)
        ) {
          result.push(s);
        }
      }
    }
    return result;
  }
);

export const selectCompletedStopsCreatedByDispatcherFilteredByShowHideButton =
  createSelector(
    selectCompletedStopsCreatedByDispatcher,
    selectShowCompletedDispatcherStops,
    (completedStops, showCompleted) => {
      if (!completedStops) {
        return null;
      }
      return showCompleted ? completedStops : [];
    }
  );

export const selectUpcomingStopsCreatedByDispatcher = createSelector(
  selectStopsCreatedByDispatcher,
  selectCompletedStopsCreatedByDispatcher,
  (allStops, completedStops) => {
    if (!allStops || !completedStops) {
      return null;
    }
    return allStops.filter((s) => !completedStops.includes(s));
  }
);

export const selectDriverCreatedCargos = createSelector(
  selectRouteWithStopsData,
  (routeData) => {
    if (!routeData) {
      return null;
    }

    const cargosCreatedByDrivers = routeData.cargos.filter(
      (c) => c.createdBy === "DRIVER"
    );
    return cargosCreatedByDrivers
      .sort(
        (a, b) =>
          DateTime.fromISO(a.createdAt).toMillis() -
          DateTime.fromISO(b.createdAt).toMillis()
      )
      .map((c) => ({
        ...c,
        stops: c.stops
          .map((s) => {
            const routeStop = routeData.routeStops.find(
              (rs) => rs.type === "CARGO_STOP" && rs.stop.id === s
            ) as RouteCargoStopNew;
            if (!routeStop) {
              return null;
              // TODO: throw err;
            }
            return routeStop.stop;
          })
          .filter(isNotNull),
      }));
  }
);

const selectClientForStopNew = (stopId: string) => (state: RootState) => {
  const cargo = state.data.routeWithStops.data?.cargos.find((c) =>
    c.stops.some((s) => s === stopId)
  )!;
  if (!cargo.clientId) {
    return null;
  }

  return selectClient(cargo.clientId)(state);
};

export const selectImagesAddedOnCargo = (cargoId: string) =>
  createSelector(selectRouteWithStopsData, (routeData) => {
    if (!routeData) {
      return false;
    }
    const cargo = routeData.cargos.find((c) => c.id === cargoId);
    if (!cargo) {
      return false;
    }

    const cargoStops = cargo.stops
      .map(
        (cs) =>
          routeData.routeStops.find(
            (rs) => rs.type === "CARGO_STOP" && rs.stop.id === cs
          ) as RouteCargoStopNew
      )
      .filter(isNotNullOrUndefined);
    return cargoStops.some((cs) => cs.stop.attachments.length > 0);
  });

export const selectShouldShowArrivalDepartureButtonsNew =
  (stopId: string) => (state: RootState) => {
    const enabledForOrg =
      !!selectMe(state)?.arrivedDepartedButtonsDefaultEnabledForOrg;
    if (!enabledForOrg) {
      return false;
    }

    const client = selectClientForStopNew(stopId)(state);
    if (!client) {
      return true;
    }
    if (client?.showArrivalDepartureToDriver === null) {
      // either not set on customer OR no customer for cargo
      return true;
    }
    return client?.showArrivalDepartureToDriver;
  };

export const selectShouldShowCubicMetersButtonNew =
  (stopId: string) => (state: RootState) => {
    const enabledForOrg =
      !!selectMe(state)?.cubicMetersButtonDefaultEnabledForOrg;
    if (!enabledForOrg) {
      return false;
    }

    const client = selectClientForStopNew(stopId)(state);
    if (client === null || client?.showAddCubicMetersToDriver === null) {
      // either not set on customer OR no customer for cargo
      return true;
    }
    return client?.showAddCubicMetersToDriver;
  };

export const selectCargoNew = (cargoId: string) =>
  createSelector(selectRouteWithStopsData, (routeData) => {
    if (!routeData) {
      return null;
    }

    return routeData.cargos.find((c) => c.id === cargoId);
  });

export const selectStopsActedOnFromSameCargo = (cargoId: string) =>
  createSelector(selectRouteWithStopsData, (routeData) => {
    if (!routeData) {
      return [];
    }
    const cargo = routeData.cargos.find((c) => c.id === cargoId)!;
    const cargoStops = cargo.stops.map(
      (cargoStopId) =>
        routeData.routeStops.find(
          (rs) => rs.type === "CARGO_STOP" && rs.stop.id === cargoStopId
        ) as RouteCargoStopNew
    );

    const cargoStopsActedOn = cargoStops
      .map((rs) => {
        const stop = rs.stop;
        const actedOnDates = [
          stop.arrivedAt,
          stop.departedAt,
          stop.completedAt,
        ].filter(Boolean);
        if (!actedOnDates.length) {
          return null;
        }
        const date = DateTime.min(
          ...actedOnDates.map((d) => DateTime.fromISO(d!))
        );
        return {
          date,
          stop,
        };
      })
      .filter(Boolean);

    return cargoStopsActedOn.sort(
      (a, b) => a!.date.toMillis() - b!.date.toMillis()
    );
  });
