export const worktimeUrlString = `/routes/:routeId/sessions/:sessionId/truck/:truckId/worktime`;
export const drivingUrlString = `/routes/:routeId/sessions/:sessionId/truck/:truckId/driving`;

export const worktimeUrl = (input: {
  routeId: string;
  sessionId: string;
  truckId: string;
}) => {
  const { routeId, truckId, sessionId } = input;
  return worktimeUrlString
    .replace(":routeId", routeId.toString())
    .replace(":sessionId", sessionId.toString())
    .replace(":truck", truckId.toString());
};

export const drivingUrl = (input: {
  routeId: string;
  sessionId: string;
  truckId: string;
}) => {
  const { routeId, truckId, sessionId } = input;
  return drivingUrlString
    .replace(":routeId", routeId.toString())
    .replace(":sessionId", sessionId.toString())
    .replace(":truck", truckId.toString());
};
