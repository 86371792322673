import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { selectStatus } from "../ducks/app/connection/selectors";

const ErrorMessages = () => {
  const status = useSelector(selectStatus);
  switch (status) {
    case "ERROR":
      return (
        <Alert severity="error">
          Der er desværre opstået en teknisk fejl. Prøv igen om et par minutter.
        </Alert>
      );
    case "OK":
      return null;
    default:
      return null;
  }
};

export default ErrorMessages;
