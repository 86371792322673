import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import { ShareOutlined } from "@mui/icons-material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import RouteAddress from "./RouteAddress";
import { Waypoint } from "../../ducks/app/session/types";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";

const WaypointStop = ({ waypoint }: { waypoint: Waypoint }) => {
  const waypointUrl = `waypoints/${waypoint.id}`;
  const navigate = useNavigate();
  const onClick = () => {
    navigate(waypointUrl);
  };
  return (
    <ListItem disablePadding>
      <ListItemButton
        disableRipple
        disableTouchRipple
        onClick={onClick}
        sx={{ px: 0 }}
      >
        <ListItemAvatar>
          <ShareOutlined />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="body1">
              <RouteAddress
                address={{
                  city: waypoint.place.city,
                  country: waypoint.place.country,
                  postcode: waypoint.place.postcode,
                  placeName: waypoint.place.placeName,
                  address: waypoint.place.address,
                }}
              />
            </Typography>
          }
          secondary={<>{waypoint.note}</>}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default WaypointStop;
