import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { apiFetch } from "../../helpers/apiRequest";

const NotebookPage = () => {
  const [notebookContent, setNotebookContent] = useState<string>("");

  useEffect(() => {
    apiFetch("/drivers-api/notebook")
      .then(async (res) => {
        const data = await res.json();
        if (data.notebookContent) {
          const clean = DOMPurify.sanitize(data.notebookContent);
          setNotebookContent(clean);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: notebookContent }}></div>
    </div>
  );
};

export default NotebookPage;
