import { useCallback, useMemo } from "react";
import throttle from "lodash/throttle";
import {
  AutoSuggestResult,
  defaultGoogleMapsApiKey,
  GooglePlaceSearch,
  SearchLocation,
} from "dora-shared";
import { reportErrorToBackend } from "./ducks/api";
import { store } from "./redux-store";

export interface AutoCompleteData {
  autocompleteService: google.maps.places.AutocompleteService;
  placesService: google.maps.places.PlacesService;
}

interface Callback<T> {
  (err: Error | null, data?: T): void;
}

const useGoogleAutoSuggestCb = () => {
  const search = useMemo(() => {
    const state = store.getState();
    const { googleMapsApiKey } = state.app.apiKeys;
    if(!googleMapsApiKey){
      setTimeout(()=>{
        throw new Error("Google maps api key not in state. Using default")
      })
    }
    const autoSuggest = new GooglePlaceSearch({
      apiKey: googleMapsApiKey || defaultGoogleMapsApiKey,
      onError: (err) => {
        console.error({ err });
        reportErrorToBackend({ err });
      },
      onSuccess: () => {},
    });
    return autoSuggest.search.bind(autoSuggest);
  }, []);
  return useCallback(
    (
      searchTerm: string,
      searchLocation: SearchLocation,
      cb: Callback<AutoSuggestResult>
    ) =>
      search(searchTerm, searchLocation).then(
        (x) => cb(null, x),
        (err) => cb(err)
      ),
    [search]
  );
};

const useGoogleAutoSuggest = () => {
  const callback = useGoogleAutoSuggestCb();
  return useMemo(
    () => throttle(callback, 200, { leading: false, trailing: true }),
    [callback]
  );
};

export const usePlacePredictions = () => ({ suggest: useGoogleAutoSuggest() });
