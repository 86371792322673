import { Stop } from "../../../ducks/app/session/types";
import useFeature from "../../../hooks/useFeature";
import { useSelector } from "react-redux";
import { selectMe } from "../../../ducks/auth/selectors";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddRefModal from "./AddRefModal";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";

const AddRefModalAndButton = (input: {
  sessionId: string;
  stop: Stop;
  cargoInvoiceRef: string | null;
}) => {
  const { stop, sessionId, cargoInvoiceRef } = input;
  const [showAddRefDialog, setShowAddRefDialog] = useState(false);
  const { t } = useTranslation(["stopPage"]);
  const addInvoiceRefFeatureEnabled = useFeature("driver-add-ref-button");
  const addInvoiceRefEnabled = useSelector(selectMe)?.canAddInvoiceRef;

  return (
    <>
      {showAddRefDialog && (
        <AddRefModal
          sessionId={sessionId!}
          stop={stop}
          onClose={() => setShowAddRefDialog(false)}
        />
      )}
      {addInvoiceRefFeatureEnabled && addInvoiceRefEnabled && (
        <Button
          onClick={() => setShowAddRefDialog(true)}
          variant="outlined"
          size="large"
          startIcon={<TagOutlinedIcon />}
        >
          {t("stopPage:addInvoiceRef")}
          {cargoInvoiceRef && <CheckIcon />}
        </Button>
      )}
    </>
  );
};

export default AddRefModalAndButton;
