import { Stop } from "../../../ducks/app/session/types";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import AddWeightModal from "./AddWeightModal";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";

const AddWeightModalAndButton = (input: {
  sessionId: string;
  stop: Stop;
  weightAdded: boolean;
}) => {
  const { stop, sessionId, weightAdded } = input;
  const [showAddWeightDialog, setShowAddWeightDialog] = useState(false);
  const { t } = useTranslation(["stopPage"]);

  return (
    <>
      {showAddWeightDialog && (
        <AddWeightModal
          sessionId={sessionId!}
          stop={{
            ...stop,
          }}
          onClose={() => setShowAddWeightDialog(false)}
        />
      )}
      <Button
        onClick={() => setShowAddWeightDialog(true)}
        variant="outlined"
        size="large"
        startIcon={<ScaleOutlinedIcon />}
      >
        {t("stopPage:addWeight")}
        {weightAdded && <CheckIcon />}
      </Button>
    </>
  );
};

export default AddWeightModalAndButton;
