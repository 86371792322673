import { StopNew } from "../../../ducks/data/route-with-stops/types";
import { useTranslation } from "react-i18next";
import { SellOutlined } from "@mui/icons-material";
import "./stop-component.scss";

const StopCmrTag = ({ stop }: { stop: StopNew }) => {
  const { t } = useTranslation(["translation"]);

  if (!stop.tag) {
    return null;
  }

  return (
    <div className="stop-component">
      <SellOutlined className="stop-component-icon" />
      <div className="flex-grow">
        {t("translation:cmrTag")}: {stop.tag}
      </div>
    </div>
  );
};

export default StopCmrTag;
