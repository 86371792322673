// This code was stolen from:
// https://observablehq.com/@mbostock/localized-number-parsing
//
// JavaScript does not provide localized number parsing, i.e. all number
// parsing in JS assumes the decimal separator to be the ".".
//
// This verbose code extracts the information, and uses that to mutate
// the string before parsing.

import Decimal from "decimal.js-light";

export default class NumberParser {
  private _group: RegExp;
  private _decimal: RegExp;
  private _numeral: RegExp;
  private _index: (key: string) => number | undefined;

  constructor(locale: string) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210
      ),
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(
      `[${parts.find((d) => d.type === "group")!.value}]`,
      "g"
    );
    this._decimal = new RegExp(
      `[${parts.find((d) => d.type === "decimal")!.value}]`
    );
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d) => index.get(d);
  }

  localizedToInvariantNumberString(string: string) {
    if (typeof string !== "string") {
      throw new TypeError("Input must be a string");
    }
    return string
      .trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index as any);
  }

  parse(string: string) {
    const val = this.localizedToInvariantNumberString(string);
    return val ? +val : NaN;
  }

  parseDecimal(s: string) {
    try {
      return new Decimal(this.localizedToInvariantNumberString(s));
    } catch {
      return null;
    }
  }
}
