import { useCallback, useMemo } from "react";
import * as turf from "@turf/turf";
import { useAppSelector } from "../../../redux-store";
import { selectDriverLocation } from "../../../ducks/app/driver-location/selectors";
import { SavedLocationSearchOption } from "./SearchOption";

/**
 * This is _intended_ to be used with lodash's sortBy.
 *
 * So if no point is specified, 0 is returned (any value could be used).
 * lodash keep the order of equal elements.
 */
export const distanceFromPoint =
  (coords: null | { lat: number; lon: number }) =>
  (location: SavedLocationSearchOption) => {
    if (!coords) {
      return 0;
    }
    const locationTurfPoint = turf.point([coords.lon, coords.lat]);

    const aCoords = location.value.data.place.coord;
    return turf.distance(
      locationTurfPoint,
      turf.point([aCoords.lon, aCoords.lat])
    );
  };

export const createClosenessSorter =
  (coords: null | { lat: number; lon: number }) =>
  (a: SavedLocationSearchOption, b: SavedLocationSearchOption) => {
    if (!coords) {
      return 0;
    }
    const locationTurfPoint = turf.point([coords.lon, coords.lat]);

    const aCoords = a.value.data.place.coord;
    const bCoords = b.value.data.place.coord;
    if (!aCoords || !bCoords) {
      return 0;
    }
    const aDistance = turf.distance(
      locationTurfPoint,
      turf.point([aCoords.lon, aCoords.lat])
    );
    const bDistance = turf.distance(
      locationTurfPoint,
      turf.point([bCoords.lon, bCoords.lat])
    );
    return aDistance - bDistance;
  };

export const useClosenessSorter = (
  coords: null | { lat: number; lon: number }
) => useMemo(() => createClosenessSorter(coords), [coords]);

const useSortByClosenessToDriverLocation = () => {
  const location = useAppSelector(selectDriverLocation);

  return {
    sortByClosenessToDriverLocation: useCallback(
      (a: SavedLocationSearchOption, b: SavedLocationSearchOption) => {
        if (!location) {
          return 0;
        }
        const locationTurfPoint = turf.point([
          location.coords.lon,
          location.coords.lat,
        ]);

        const aCoords = a.value.data.place.coord;
        const bCoords = b.value.data.place.coord;
        if (!aCoords || !bCoords) {
          return 0;
        }
        const aDistance = turf.distance(
          locationTurfPoint,
          turf.point([aCoords.lon, aCoords.lat])
        );
        const bDistance = turf.distance(
          locationTurfPoint,
          turf.point([bCoords.lon, bCoords.lat])
        );
        return aDistance - bDistance;
      },
      [location]
    ),
  };
};

export default useSortByClosenessToDriverLocation;
