import api, { createDecoder } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { savedLocationsT } from "dora-contracts";
import { SavedLocations } from "./types";

const prefix = "data/saved-locations";

type State = { entries: SavedLocations };

const initialState: State = { entries: [] };

export const loadSavedLocations = createAsyncThunk(
  `${prefix}/load-saved-locations`,
  async (_: void, thunkApi) => {
    return await api(thunkApi).get({
      url: "/drivers-api/saved-locations",
      decoder: createDecoder(savedLocationsT),
    });
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clearSavedLocations(state) {
      state.entries = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSavedLocations.fulfilled, (state, action) => {
      state.entries = action.payload;
    });
  },
});

export const actions = slice.actions;

export default slice.reducer;
