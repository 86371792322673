import { StopNew } from "../../../ducks/data/route-with-stops/types";
import "./stop-component.scss";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CargoDimensions from "../../SessionsPage/StopPage/CargoDimensions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectHideLoadmeters } from "../../../ducks/data/driver-app-settings/selectors";
import { selectShouldShowEditCargoButtons } from "../../../ducks/auth/selectors";
import { useState } from "react";
import EditLoadmetersDialog from "../../SessionsPage/StopPage/edit-dialogs/EditLoadmetersDialog";
import { useRoutePageContext } from "../RoutePageContext";

const StopDimensions = ({ stop }: { stop: StopNew }) => {
  const { t } = useTranslation(["translation", "types"]);
  const [shouldShowEditLoadmetersDialog, setShouldShowEditLoadmetersDialog] =
    useState(false);
  const hideLoadmeters = useSelector(selectHideLoadmeters);
  const shouldShowEditCargoButtons = useSelector(
    selectShouldShowEditCargoButtons
  );

  const { reloadData } = useRoutePageContext();

  if (
    !stop.colli &&
    (!stop.lm || hideLoadmeters) &&
    !stop.pieces?.length &&
    !stop.weight &&
    !stop.cubicMeters &&
    !stop.temperature
  ) {
    return null;
  }

  return (
    <div className="stop-component">
      {shouldShowEditLoadmetersDialog && (
        <EditLoadmetersDialog
          cargoId={stop.cargoId}
          stopId={stop.id}
          onClose={() => {
            reloadData();
            setShouldShowEditLoadmetersDialog(false);
          }}
          loadmeters={stop.lm}
        />
      )}
      <SquareFootOutlinedIcon className="stop-component-icon" />
      <div className="flex-grow">
        <div>
          {stop.colli && (
            <div>
              {t("translation:colli")}: {stop.colli}
            </div>
          )}
          {!hideLoadmeters && (
            <div className="flex items-center justify-between">
              <div className="flex-grow">
                {t("translation:Loadmeter", {
                  count: +stop.lm,
                })}
              </div>
              {shouldShowEditCargoButtons && (
                <Button onClick={() => setShouldShowEditLoadmetersDialog(true)}>
                  <EditOutlinedIcon />
                </Button>
              )}
            </div>
          )}
          {stop.pieces &&
            stop.pieces.map((p, i) => (
              <div key={i}>
                {p.quantity} x {t(`types:unitTypes.unit_${p.unit}`)}
              </div>
            ))}
        </div>
        <div>
          <CargoDimensions type={stop.type} size={stop} />
        </div>
        {stop.weight && <div>{stop.weight} kg</div>}
        {stop.cubicMeters && <div>{stop.cubicMeters} m³</div>}
        {stop.temperature && <div>{stop.temperature} °C</div>}
      </div>
    </div>
  );
};

export default StopDimensions;
