import { RouteCargoStopNew } from "../../../ducks/data/route-with-stops/types";
import "./DispatcherCreatedStop.scss";
import { useSelector } from "react-redux";
import {
  selectCargoNew,
  selectIsRowExpanded,
  selectStopsActedOnFromSameCargo,
} from "../../../ducks/data/route-with-stops/selectors";
import IconButton from "@mui/material/IconButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import CargoInfoNew from "../CargoInfoNew";
import StopDimensions from "../StopComponents/StopDimensions";
import StopLoadingRefContact from "../StopComponents/StopLoadingRefContact";
import StopOpeningTime from "../StopComponents/StopOpeningTime";
import StopPhone from "../StopComponents/StopPhone";
import StopCmrTag from "../StopComponents/StopCmrTag";
import StopDocuments from "../StopComponents/StopDocuments";
import useFeature from "../../../hooks/useFeature";
import StopButtons from "../StopComponents/StopButtons";
import CheckIcon from "@mui/icons-material/Check";
import { selectClient } from "../../../ducks/data/clients/selectors";
import { selectShouldShowEditCargoButtons } from "../../../ducks/auth/selectors";
import { useTranslation } from "react-i18next";
import StopsFromSameCargo from "../../SessionsPage/StopPage/StopsFromSameCargo";
import StopAddress from "../StopComponents/StopAddress";
import TimeTracking from "../StopComponents/TimeTracking";
import { uniq } from "lodash";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../../redux-store";
import { collapseRow, expandRow } from "../../../ducks/data/route-with-stops";

const DispatcherCreatedCargoStop = ({
  cargoStop,
}: {
  cargoStop: RouteCargoStopNew;
}) => {
  const { stop } = cargoStop;
  const areDocumentsEnabled = useFeature("stops-documents");
  const expanded = useSelector(
    selectIsRowExpanded({
      id: cargoStop.stop.id,
      type: "dispatcherCreatedStop",
    })
  );

  const dispatch = useAppDispatch();

  const onTitleClick = () => {
    if (expanded) {
      dispatch(collapseRow());
    } else {
      dispatch(
        expandRow({ id: cargoStop.stop.id, type: "dispatcherCreatedStop" })
      );
    }
  };
  const { t } = useTranslation(["stopPage"]);

  const cargo = useSelector(selectCargoNew(stop.cargoId));
  const client = useSelector(selectClient(cargo?.clientId || null));
  const shouldShowEditCargoButtons = useSelector(
    selectShouldShowEditCargoButtons
  );
  const stopsActedOnFromSameCargo = useSelector(
    selectStopsActedOnFromSameCargo(stop.cargoId)
  );

  if (!cargo) {
    return null;
  }

  const clientAndInvoiceRef = [client?.name, cargo.invoiceRef]
    .filter(Boolean)
    .join(", ");

  const rows = uniq([
    stop.place.placeName,
    stop.place.address,
    [stop.place.city, stop.place.postcode].filter(Boolean).join(", "),
  ]).filter(Boolean);

  const onAddDropoff = (stopId: string) => {
    dispatch(expandRow({ id: stopId, type: "dispatcherCreatedStop" }));
  };

  return (
    <div
      className="dispatcher-created-stop"
      style={{ marginLeft: stop.type === "DROPOFF" ? 24 : 0 }}
    >
      <div onClick={onTitleClick}>
        <div className="stop-title flex items-center mt-2">
          <div className="flex flex-grow">
            {stop.completedAt || stop.departedAt ? (
              <>
                <CheckIcon />
                &nbsp;
              </>
            ) : null}
            {rows[0]}
          </div>
          <IconButton style={{ padding: 0 }}>
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </div>
        {rows.slice(1).map((row, i) => (
          <div className="stop-title" key={i}>
            {row}
          </div>
        ))}
        <div className="stop-date">
          {DateTime.fromFormat(stop.date, "yyyy-MM-dd").toFormat("dd.MM.yyyy")}
        </div>
        {clientAndInvoiceRef && (
          <div className="stop-date">{clientAndInvoiceRef}</div>
        )}
        <div className="mb-2 w-full"></div>
      </div>
      {expanded && (
        <div className="dispatcher-created-stop__content">
          <div className="mt-2">
            {cargo.timeTracking && (
              <TimeTracking cargoId={cargo.id} stopId={stop.id} />
            )}
          </div>
          <CargoInfoNew
            cargoId={cargo.id}
            stopId={stop.id}
            description={cargo.description}
            shouldShowDriverEditButtons={shouldShowEditCargoButtons}
            clientId={cargo.clientId}
            cargoType={cargo.type}
          />
          {/* <div className="mt-2">{t("stopPage:stopInfo")}:</div> */}
          <div className="flex flex-col gap-3 mt-2">
            <StopAddress stop={stop} />
            <StopDimensions stop={stop} />
            <StopLoadingRefContact stop={stop} />
            <StopOpeningTime stop={stop} />
            <StopPhone stop={stop} />
            <StopCmrTag stop={stop} />
            {areDocumentsEnabled && <StopDocuments stop={stop} />}
          </div>
          {/* <div
            className="mt-2"
            style={{ color: "var(--gray-400", fontSize: 14, fontWeight: 400 }}
          >
            -----
          </div> */}

          <StopsFromSameCargo
            className="mt-3"
            stop={{
              ...stop,
              cargo_id: cargo.id,
            }}
          />

          <div className="mt-4">
            <div className="font-semibold">{t("stopPage:log")}:</div>

            {stopsActedOnFromSameCargo.map((cs, i) => {
              const address = [
                cs!.stop.place?.address,
                cs!.stop.place?.city,
                cs!.stop.place?.postcode,
              ]
                .filter(Boolean)
                .join(", ");
              return (
                <div
                  key={i}
                  className="p-1 mt-2"
                  style={{
                    background:
                      cs!.stop.id === cargoStop.stop.id ? "#EFEFEF" : "white",
                  }}
                >
                  <div>{cs!.date.toFormat("HH:mm dd.MM.yyyy ")}</div>
                  <div>{address}</div>
                </div>
              );
            })}
          </div>
          <div className="mt-2">
            <StopButtons
              cargoId={stop.cargoId}
              hideAddDropoffButton={!cargo.driverCanAddDropoffs}
              stop={stop}
              cargoInvoiceRef={cargo.invoiceRef}
              onAddDropoff={onAddDropoff}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default DispatcherCreatedCargoStop;
