import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";
import { selectFeature } from "../../features/selectors";

const selectSavedLocations = (state: RootState) =>
  selectFeature("saved-locations-visible-to-drivers")(state)
    ? state.data.savedLocations.entries
    : [];

export const selectFavoriteLocations = createSelector(
  selectSavedLocations,
  (entries) => entries.filter((l) => l.type === "FAVORITE")
);

export const selectTerminalLocations = createSelector(
  selectSavedLocations,
  (entries) => entries.filter((l) => l.type === "TERMINAL")
);
