import { createContext, useContext } from "react";
import NumberParser from "./NumberParser";
import Decimal from "decimal.js-light";
import { DateTime } from "luxon";

class L10nEngine {
  locale: string;
  numberParser: NumberParser;
  constructor(locale = "da-DK") {
    this.locale = locale;
    this.numberParser = new NumberParser(locale);
  }

  private toNumber(n: string | number | Decimal): number {
    switch (typeof n) {
      case "number":
        return n;
      case "string":
        return new Decimal(n).toNumber();
      default:
        return n.toNumber();
    }
  }

  private asDateTime(d: DateTime | string) {
    return (
      d instanceof DateTime ? d : DateTime.fromISO(d, { setZone: true })
    ).setLocale(this.locale);
  }

  formatNumber(n: string | number | Decimal): string {
    return new Intl.NumberFormat(this.locale).format(this.toNumber(n));
  }

  formatCurrency(n: string | number | Decimal): string {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(
      typeof n === "number" ? n : typeof n === "string" ? +n : n.toNumber()
    );
  }

  formatDate(d: string) {
    return this.asDateTime(d).toFormat("dd.MM.yyyy");
  }

  formatDateAndTime(d: DateTime | string) {
    return this.asDateTime(d).toLocaleString(DateTime.DATETIME_SHORT);
  }

  parseNumber(s: string): number {
    return this.numberParser.parse(s);
  }

  parseDecimal(s: string): Decimal | null {
    return this.numberParser.parseDecimal(s);
  }

  invariantToLocalizedNumberString(input?: string): string | undefined {
    return input && this.formatNumber(+input);
  }
}

export const LocalizationContext = createContext(new L10nEngine());

export const LocalizationProvider = ({
  locale,
  children,
}: {
  locale: string;
  children: React.ReactNode;
}) => (
  <LocalizationContext.Provider value={new L10nEngine(locale)}>
    {children}
  </LocalizationContext.Provider>
);

export const useL10n = () => useContext(LocalizationContext);
