import { useTranslation } from "react-i18next";
import { Stop } from "../../../ducks/app/session/types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectRelatedStopsFromCargo } from "../../../ducks/app/session/selectors";
import IconButton from "@mui/material/IconButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import "./StopsFromSameCargo.scss";
import classnames from "classnames";

const StopsFromSameCargo = ({
  stop,
  className,
}: {
  stop: Stop;
  className?: string;
}) => {
  const { t } = useTranslation("stopPage");

  const [expanded, setExpanded] = useState(false);
  const stopsFromSameCargo = useSelector(
    selectRelatedStopsFromCargo(stop.id, stop.cargo_id)
  );

  const onArrowClick = () => {
    setExpanded(!expanded);
  };

  const hasPickups = stopsFromSameCargo.pickups.length > 0;
  const hasDropoffs = stopsFromSameCargo.dropoffs.length > 0;

  if (!hasPickups && !hasDropoffs) {
    return null;
  }

  const classes = classnames("stops-from-same-cargo", className);

  return (
    <div className={classes}>
      <div className="flex items-center justify-between">
        <div className="stops-from-same-cargo__title">
          {hasPickups ? t("pickups") : t("dropoffs")}
        </div>
        <IconButton onClick={onArrowClick}>
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      {expanded && (
        <div className="mt-2">
          {hasPickups &&
            stopsFromSameCargo.pickups.map((stop) => (
              <StopRow key={stop.id} stop={stop} />
            ))}
          {hasDropoffs && (
            <>
              {hasPickups && (
                <div className="stops-from-same-cargo__title mt-7">
                  {t("dropoffs")}
                </div>
              )}
              {stopsFromSameCargo.dropoffs.map((stop) => (
                <StopRow key={stop.id} stop={stop} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const StopRow = ({ stop }: { stop: Stop }) => {
  return (
    <div className="mt-3">
      <div>{stop.place?.address}</div>
      <div className="flex gap-2">
        {stop.place?.postcode && <div>{stop.place?.postcode}</div>}
        {stop.place?.city && <div>{stop.place?.city}</div>}
        {stop.place?.country && <div>{stop.place?.country}</div>}
      </div>
    </div>
  );
};

export default StopsFromSameCargo;
