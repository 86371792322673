import { useMemo } from "react";
import * as rootStore from "../redux-store";
import mapValues from "lodash/mapValues";

type Actions = {
  [key: string]: rootStore.ActionCreator<any>;
};

type GetActionReturnResult<T> = T extends rootStore.AppThunkAction
  ? ReturnType<T>
  : void;

type GetActionResult<T extends rootStore.ActionCreator<any>> =
  GetActionReturnResult<ReturnType<T>>;

type GetReturnedAction<T extends Actions> = {
  [key in keyof T]: (...args: Parameters<T[key]>) => GetActionResult<T[key]>;
};

export const useActions = <T extends Actions>(
  actions: T
): GetReturnedAction<T> => {
  const dispatch = rootStore.useAppDispatch();
  return useMemo(
    () =>
      mapValues(
        actions,
        (action) => (args: Parameters<typeof action>) => dispatch(action(args))
      ) as any,
    [actions, dispatch]
  );
};
