import { timeTrackingEventT } from "dora-contracts";
import api, { noContentDecoder } from "../../api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPosition } from "../../../helpers/position";
import { DateTime } from "luxon";

const prefix = "app/cargo-time-tracking";

const reqWithEndpoint = (endpoint: string) => {
  return createAsyncThunk(
    `${prefix}/${endpoint}`,
    async (
      input: {
        cargoId: string;
        data: {
          time: DateTime;
          truckId: string;
          routeId: string;
          stopId: string;
        };
      },
      thunkApi
    ) => {
      const position = await getPosition();
      return api(thunkApi).post({
        url: `/drivers-api/cargos/${input.cargoId}/time-tracking/${endpoint}`,
        body: timeTrackingEventT.encode({
          ...input.data,
          position: position
            ? {
                lat: position.lat,
                lon: position.lon,
                accuracy: position.accuracy,
              }
            : null,
        }),
        decoder: noContentDecoder,
      });
    }
  );
};

export const startCargoTimeTracking = reqWithEndpoint("start");
export const pauseCargoTimeTracking = reqWithEndpoint("pause");
export const resumeCargoTimeTracking = reqWithEndpoint("resume");
export const stopCargoTimeTracking = reqWithEndpoint("stop");
