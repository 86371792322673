import { useSelector } from "react-redux";
import {
  selectDriverCreatedCargos,
  selectIsRowExpanded,
} from "../../../ducks/data/route-with-stops/selectors";
import IconButton from "@mui/material/IconButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import CargoInfoNew from "../CargoInfoNew";
import { CargoNew, StopNew } from "../../../ducks/data/route-with-stops/types";
import "./DriverCreatedCargos.scss";
import DriverCreatedCargoStop from "./DriverCreatedCargoStop";
import StopButtons from "../StopComponents/StopButtons";
import { selectClient } from "../../../ducks/data/clients/selectors";
import { selectShouldShowEditCargoButtons } from "../../../ducks/auth/selectors";
import { useTranslation } from "react-i18next";
import TimeTracking from "../StopComponents/TimeTracking";
import { useAppDispatch } from "../../../redux-store";
import { collapseRow, expandRow } from "../../../ducks/data/route-with-stops";

const DriverCreatedCargos = () => {
  const { t } = useTranslation();
  const driverCreatedCargos = useSelector(selectDriverCreatedCargos);

  if (!driverCreatedCargos) {
    return null;
  }

  return driverCreatedCargos.length ? (
    <>
      <div style={{ color: "var(--gray-500)", fontSize: 14, fontWeight: 400 }}>
        {t("driverCreatedOrders")}:
      </div>
      {driverCreatedCargos.map((cargo, i) => (
        <DriverCreatedCargo key={i} cargo={cargo} />
      ))}
    </>
  ) : null;
};

const DriverCreatedCargo = ({
  cargo,
}: {
  cargo: Omit<CargoNew, "stops"> & { stops: StopNew[] };
}) => {
  const expanded = useSelector(
    selectIsRowExpanded({
      id: cargo.id,
      type: "driverCreatedOrder",
    })
  );

  const dispatch = useAppDispatch();

  const onTitleClick = () => {
    if (expanded) {
      dispatch(collapseRow());
    } else {
      dispatch(expandRow({ id: cargo.id, type: "driverCreatedOrder" }));
    }
  };

  const client = useSelector(selectClient(cargo.clientId));
  const shouldShowEditCargoButtons = useSelector(
    selectShouldShowEditCargoButtons
  );

  const { t } = useTranslation(["stopPage"]);

  let firstRow =
    client?.name ||
    (cargo.stops.length > 1
      ? `${cargo.stops[0].place?.postcode} - ${cargo.stops[cargo.stops.length - 1].place?.postcode}`
      : cargo.stops[0]?.place?.postcode);

  if (cargo.invoiceRef) {
    firstRow += ` , ${cargo.invoiceRef}`;
  }

  const activeCargoStop = cargo.stops[cargo.stops.length - 1];

  return (
    <div className="driver-created-cargo">
      <div onClick={onTitleClick} className="driver-created-cargo__title">
        <div>{firstRow}</div>
        <IconButton>
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      {expanded && (
        <div className="driver-created-cargo__content">
          <div>
            <div className="mt-2">
              {cargo.timeTracking && (
                <TimeTracking cargoId={cargo.id} stopId={activeCargoStop.id} />
              )}
            </div>
            <CargoInfoNew
              cargoId={cargo.id}
              stopId={cargo.stops[cargo.stops.length - 1].id}
              shouldShowDriverEditButtons={shouldShowEditCargoButtons}
              description={cargo.description}
              cargoType={cargo.type}
              clientId={cargo.clientId}
            />
          </div>
          <div className="driver-created-cargo__expanded-log">
            {t("stopPage:expandedLog")}:
          </div>
          {cargo.stops.map((stop, j) => (
            <div className="mb-2" key={j}>
              <DriverCreatedCargoStop
                key={j}
                stop={stop}
                cargoInvoiceRef={cargo.invoiceRef}
                active={activeCargoStop.id === stop.id}
              />
            </div>
          ))}
          <StopButtons
            cargoId={cargo.id}
            stop={activeCargoStop}
            cargoInvoiceRef={cargo.invoiceRef}
            onAddDropoff={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default DriverCreatedCargos;
