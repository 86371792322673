import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as selectors from "../ducks/auth/selectors";
import * as i18n from "./i18n";

const ReduxLink = () => {
  const defaultLang = useSelector(selectors.selectLanguage) as "da" | "en";
  // | "da"
  // | "en"
  // | "da"
  // | "en";
  useEffect(() => {
    i18n.changeLanguage(defaultLang);
    document.documentElement.lang = defaultLang;
  }, [defaultLang]);
  return null;
};

export default ReduxLink;
