import { ParsedText } from "dora-shared";
import parse from "autosuggest-highlight/parse";
import classnames from "classnames";
import "./RenderParsedText.scss";

const RenderParsedText = ({
  item,
  secondary,
}: {
  item: ParsedText;
  secondary?: boolean;
}) => {
  const parsed = parse(
    item.label,
    item.highlights.map((x) => [x.start, x.end])
  );
  return (
    <div
      className={classnames("match-group", {
        "match-group--secondary": secondary,
      })}
    >
      {parsed.map((value, idx) => (
        <span
          key={idx + value.text}
          className={classnames("match-group__match", {
            "match-group__match--highlight": value.highlight,
          })}
        >
          {value.text}
        </span>
      ))}
    </div>
  );
};

export default RenderParsedText;
