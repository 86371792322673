import { combineReducers } from "redux";
import clients from "./clients";
import savedLocations from "./saved-locations";
import driverAppSettings from "./driver-app-settings";
import routeWithStops from "./route-with-stops";

export default combineReducers({
  clients,
  driverAppSettings,
  savedLocations,
  routeWithStops,
});
