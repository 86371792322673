import { useMemo } from "react";
import GenericAutocomplete from "../../components/GenericAutocomplete";
import { cargoTypes, CargoType } from "./types";
import { useTranslation } from "react-i18next";

interface CargoTypesSelectorProps {
  value: CargoType;
  onValueChange: (v: CargoType) => void;
}

const CargoTypesSelector = ({
  value,
  onValueChange,
}: CargoTypesSelectorProps) => {
  const { t } = useTranslation(["types", "stopPage"]);
  const options = useMemo(
    () =>
      cargoTypes.map((id) => ({ id, label: t("types:type", { context: id }) })),
    [t]
  );
  return (
    <GenericAutocomplete
      options={options}
      value={value}
      label={t("stopPage:cargoType")}
      onChange={(v) => v && onValueChange(v)}
    />
  );
};

export default CargoTypesSelector;
