import { createDriverAddCargoTypes } from "dora-contracts";
import * as t from "io-ts";

export const { addDropoffT } = createDriverAddCargoTypes({ decimal: t.string });

export interface CargoLocation {
  googlePlaceId?: string;
  placeName: string;
  address: string | null;
  postcode: string | null;
  city: string | null;
  country: string | null;
  position: { lat: number; lon: number };
}
