import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CargoType } from "dora-contracts";
import CargoTypesSelector from "../../../AddNewCargoPage/CargoTypesSelector";
import { updateCargoStop } from "../../../../ducks/app/update-cargo";
import { useAppDispatch } from "../../../../redux-store";

type EditCargoTypeDialogProps = {
  cargoId: string;
  stopId: string;
  type: CargoType | null;
  onClose: () => void;
};

const EditCargoTypeDialog = (props: EditCargoTypeDialogProps) => {
  const { onClose, cargoId, stopId } = props;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [type, setType] = useState<CargoType | null>(props.type);

  const onSaveButtonClick = async () => {
    try {
      setIsSaving(true);
      await dispatch(
        updateCargoStop({ cargoId, stopId, data: { type: type! } })
      );
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  return (
    <Dialog fullWidth open onClose={() => !isSaving && onClose()}>
      <DialogTitle>{t("editDialogs.cargoTypeTitle")}</DialogTitle>
      <div style={{ paddingTop: 0 }}>
        {/* div used for overwriting a global style for dialog contents*/}
      </div>
      <DialogContent>
        <CargoTypesSelector
          value={type || "PALLET_GOODS"}
          onValueChange={(cargoType) => setType(cargoType)}
        />
      </DialogContent>
      <DialogActions className="mx-4 mb-2">
        <Button
          disabled={isSaving}
          onClick={onClose}
          variant="outlined"
          size="large"
          fullWidth
        >
          {t("editDialogs.cancelButton")}
        </Button>
        <Button
          disabled={isSaving || props.type === type}
          onClick={onSaveButtonClick}
          variant="contained"
          fullWidth
          size="large"
        >
          {t("editDialogs.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCargoTypeDialog;
