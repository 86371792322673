import { Dialog } from "@mui/material";
import { StopNew } from "../../../ducks/data/route-with-stops/types";
import {
  StopDriverArrived,
  StopDriverDeparted,
} from "../../SessionsPage/StopPage/StopDriverArrivedDeparted";
import { useParams } from "../../SessionsPage/hooks";
import { useSelector } from "react-redux";
import { selectShouldShowArrivalDepartureButtonsNew } from "../../../ducks/data/route-with-stops/selectors";
import { PickupDropoffButton } from "../../SessionsPage/StopPage/StopCompleted";
import AddImage from "../../SessionsPage/StopPage/AddImage";
import AddCubicMetersModalAndButton from "../../SessionsPage/modals/AddCubicMetersModalAndButton";
import AddCommentModalAndButton from "../../SessionsPage/modals/AddCommentModalAndButton";
import AddWeightModalAndButton from "../../SessionsPage/modals/AddWeightModalAndButton";
import AddRefModalAndButton from "../../SessionsPage/modals/AddRefModalAndButton";
import Button from "@mui/material/Button";
import { useMemo, useState } from "react";
import EditAddressDialog from "../../SessionsPage/StopPage/edit-dialogs/EditAddressDialog";
import { useRoutePageContext } from "../RoutePageContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const EditDriverCreatedCargoStopDialog = ({
  stop,
  onClose,
  cargoInvoiceRef,
}: {
  stop: StopNew;
  onClose: () => void;
  cargoInvoiceRef: string | null;
}) => {
  const [shouldShowEditAddressDialog, setShouldShowEditAddressDialog] =
    useState(false);
  const { sessionId } = useParams();
  const shouldShowArrivalDepartureButtons = useSelector(
    selectShouldShowArrivalDepartureButtonsNew(stop.id)
  );

  const { t } = useTranslation(["stopPage"]);

  const podNumberForStop = stop.attachments.filter(
    (a) => a.documentType === "POD"
  ).length;

  const stopWithCargoId = {
    ...stop,
    cargo_id: stop.cargoId,
  };

  const stopAddress = useMemo(() => {
    return [
      stop.place.placeName,
      stop.place.address,
      stop.place.postcode,
      stop.place.city,
    ]
      .filter(Boolean)
      .join(", ");
  }, [stop]);

  const { reloadData } = useRoutePageContext();

  return (
    <Dialog open onClose={onClose}>
      {shouldShowEditAddressDialog && (
        <EditAddressDialog
          cargoId={stop.cargoId}
          stopId={stop.id}
          onClose={() => {
            reloadData();
            setShouldShowEditAddressDialog(false);
          }}
        />
      )}
      <div className="relative p-6">
        <div style={{ top: 12, right: 12, position: "absolute" }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>{t("stopPage:editStop")}</div>
        <div className="flex flex-col gap-2 mt-4">
          <Button
            variant="contained"
            onClick={() => setShouldShowEditAddressDialog(true)}
          >
            {stopAddress}
          </Button>
          {shouldShowArrivalDepartureButtons && (
            <div className="flex gap-2 items-center justify-between">
              <StopDriverArrived
                sessionId={sessionId as string}
                stop={stopWithCargoId}
              />
              <StopDriverDeparted
                sessionId={sessionId as string}
                stop={stopWithCargoId}
              />
            </div>
          )}
          <PickupDropoffButton
            stop={stopWithCargoId}
            sessionId={sessionId || ""}
          />
          <AddImage
            sessionId={sessionId as string}
            cargoId={stop.cargoId}
            stopId={stop.id}
            added={!!podNumberForStop}
            imagesForStop={podNumberForStop}
            onSuccess={reloadData}
          />
          <AddCommentModalAndButton
            sessionId={sessionId!}
            stop={{
              ...stop,
              cargo_id: stop.cargoId,
            }}
            comments={stop.comments}
          />
          <AddWeightModalAndButton
            sessionId={sessionId!}
            stop={{
              ...stop,
              cargo_id: stop.cargoId,
            }}
            weightAdded={stop.weightAdded}
          />
          <AddCubicMetersModalAndButton
            sessionId={sessionId as string}
            stop={{
              ...stop,
              cargo_id: stop.cargoId,
            }}
            volumeAdded={stop.volumeAdded}
          />
          <AddRefModalAndButton
            sessionId={sessionId as string}
            stop={{
              ...stop,
              cargo_id: stop.cargoId,
            }}
            cargoInvoiceRef={cargoInvoiceRef}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default EditDriverCreatedCargoStopDialog;
