import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

interface Option {
  id: string;
  label: string;
}

function GenericAutocomplete<T extends Option>({
  label,
  options,
  value,
  onChange,
  required,
  ...rest
}: {
  label: string;
  options: T[];
  value: string | null;
  onChange: (newValue: T["id"] | null) => void;
  required?: boolean;
  fullWidth?: boolean;
}) {
  const selectedValue = useMemo(
    () => options.find((x) => x.id === value) || null,
    [value, options]
  );
  return (
    <Autocomplete
      options={options}
      value={selectedValue}
      fullWidth={rest.fullWidth}
      onChange={(_e, v) => {
        const newValue = typeof v === "string" ? v : v?.id;
        onChange(newValue || null);
      }}
      renderInput={(params) => (
        <TextField {...params} required={required} label={label} />
      )}
    />
  );
}

export default GenericAutocomplete;
