import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import reducer from "./ducks";
import { DateTime } from "luxon";
import Decimal from "decimal.js-light";

export const createStore = () =>
  configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== "production" && {
      serialize: {
        replacer: (_key, value) => {
          if (value instanceof Decimal) {
            return { $type: "Decimal", value: value.toString() };
          }
          if (value instanceof DateTime) {
            return { $type: "DateTime", value: value.toISO() };
          }
          return value;
        },
        reviver: (_key, value: any) => {
          if (typeof value === "object" && value !== null && "$type" in value) {
            switch (value.$type) {
              case "Decimal":
                return new Decimal(value.data);
              case "DateTime":
                return DateTime.fromISO(value.data, { setZone: true });
            }
          }
        },
      },
    },
  });

export type AppThunkAction<T = void> = ThunkAction<
  T,
  RootState,
  any,
  AnyAction
>;
export type RootState = ReturnType<typeof reducer>;
export const store = createStore();
export type RootStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type Action = Parameters<AppDispatch>[0];
export type ActionCreator<T extends any[]> = (...args: T) => Action;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: typeof useSelector = (selector) =>
  useSelector(selector);
