import * as t from "io-ts";
import { withFallback } from "io-ts-types";
import { createSlice } from "@reduxjs/toolkit";
import api, { createDecoder } from "../../api";
import { DateTime } from "luxon";
import Decimal from "decimal.js-light";
import { CargoLocation } from "./types";
import { loadSession } from "../session";
import { AppThunkAction } from "../../../redux-store";
import { getPosition } from "../../../helpers/position";
import {
  CargoType,
  createDriverAddCargoTypes,
  nullable,
  decimal,
} from "dora-contracts";

const prefix = "app/add-cargo";

const initialState = {};

type GetLocation = {
  getCargoLocation: () => CargoLocation | Promise<CargoLocation | null>;
};

const { createCargoT } = createDriverAddCargoTypes({ decimal });

export const addCargo =
  ({
    sessionId,
    cargoType,
    invoiceRef,
    description,
    pickupTime,
    dropoffDate,
    lm,
    getPickupLocation,
    clientId,
    skipCompleteEvent = false,
  }: {
    sessionId: string;
    cargoType: CargoType;
    invoiceRef: string;
    description: string;
    pickupTime: DateTime;
    dropoffDate: DateTime;
    lm: Decimal;
    getPickupLocation: GetLocation;
    clientId: string | null;
    skipCompleteEvent: boolean;
  }): AppThunkAction<Promise<{ cargoId: string; pickupId: string } | null>> =>
  async (dispatch) => {
    const pickup = await getPickupLocation.getCargoLocation();
    const position = await getPosition();
    if (!pickup) {
      throw new Error("Missing pickup");
    }
    const result = await api({ dispatch }).post({
      url: `/drivers-api/cargos${skipCompleteEvent ? "?skipCompleteEvent=true" : ""}`,
      body: createCargoT.encode({
        sessionId,
        pickupTime,
        dropoffDate: dropoffDate.toISODate(),
        invoiceRef,
        cargo: {
          type: cargoType,
          lm,
          description,
        },
        clientId,
        pickup,
        position,
        dropoff: null,
      }),
      decoder: createDecoder(
        withFallback(
          nullable(
            t.strict({
              cargoId: t.string,
              pickupId: t.string,
            })
          ),
          null
        )
      ),
    });
    await dispatch(loadSession(sessionId));
    return result;
  };

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
});

export default slice.reducer;
