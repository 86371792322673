import * as t from "io-ts";
import {
  createDriverSessionTypes,
  createStopDocumentTypes,
} from "dora-contracts";

const {
  cargoT,
  clientT,
  stopT,
  routeWaypointT,
  routeCargoStopT,
  routeStopT,
  sessionResponseT,
  addEventT,
} = createDriverSessionTypes({
  decimal: t.string,
  date: t.string,
  dateTime: t.string,
});

export { sessionResponseT, addEventT };

const { stopDocumentT } = createStopDocumentTypes({ dateTime: t.string });

export type StopDocument = t.TypeOf<typeof stopDocumentT>;
export const documentsT = t.array(stopDocumentT);

export const { sessionT, sessionsT, attachmentT } = createDriverSessionTypes({
  decimal: t.string,
  date: t.string,
  dateTime: t.string,
});

export type Stop = t.TypeOf<typeof stopT>;
export type Waypoint = t.TypeOf<typeof routeWaypointT>;
export type RouteStop = t.TypeOf<typeof routeStopT>;
export type RouteCargoStop = t.TypeOf<typeof routeCargoStopT>;
export type Cargo = t.TypeOf<typeof cargoT>;
export type Client = t.TypeOf<typeof clientT>;
export type Attachment = t.TypeOf<typeof attachmentT>;
export type Session = t.TypeOf<typeof sessionT>;
export type Sessions = t.TypeOf<typeof sessionsT>;
export type SessionResponse = t.TypeOf<typeof sessionResponseT>;
