import { StopNew } from "../../../ducks/data/route-with-stops/types";
import { useL10n } from "../../../l10n";
import useFeature from "../../../hooks/useFeature";
import StopLoadingRefContact from "../StopComponents/StopLoadingRefContact";
import StopOpeningTime from "../StopComponents/StopOpeningTime";
import StopPhone from "../StopComponents/StopPhone";
import StopCmrTag from "../StopComponents/StopCmrTag";
import StopDocuments from "../StopComponents/StopDocuments";
import clsx from "clsx";
import "./DriverCreatedCargoStop.scss";
import { useEffect, useMemo, useState } from "react";
import * as featureActions from "../../../ducks/features";
import { useAppDispatch } from "../../../redux-store";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import StopDimensions from "../StopComponents/StopDimensions";
import EditDriverCreatedCargoStopDialog from "./EditDriverCreatedCargoStopDialog";
import StopDateAndTime from "../StopComponents/StopDateAndTime";

const DriverCreatedCargoStop = ({
  stop,
  active,
  cargoInvoiceRef,
}: {
  stop: StopNew;
  active?: boolean;
  cargoInvoiceRef: string | null;
}) => {
  const l10n = useL10n();
  const areDocumentsEnabled = useFeature("stops-documents");
  const dispatch = useAppDispatch();

  const [shouldShowEditStopDialog, setShouldShowEditStopDialog] =
    useState(false);

  const classes = clsx("driver-created-cargo-stop", {
    "driver-created-cargo-stop--active": active,
  });

  useEffect(() => {
    dispatch(featureActions.initialize());
  }, [dispatch]);

  const address = useMemo(() => {
    let stopLocation = "";
    if (stop.place.address) {
      stopLocation += stop.place.address;
    }
    if (stop.place.postcode) {
      stopLocation += ` ${stop.place.postcode}`;
    }
    if (stop.place.city) {
      stopLocation += ` ${stop.place.city}`;
    }
    return stopLocation;
  }, [stop]);

  return (
    <div className={classes}>
      {shouldShowEditStopDialog && (
        <EditDriverCreatedCargoStopDialog
          stop={stop}
          cargoInvoiceRef={cargoInvoiceRef}
          onClose={() => setShouldShowEditStopDialog(false)}
        />
      )}
      <div className="flex items-center justify-between">
        <div>{address}</div>
        {!active && (
          <div
            className="driver-created-cargo-stop__dots"
            onClick={() => setShouldShowEditStopDialog(true)}
          >
            <MoreHorizIcon />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-3 mt-2">
        <StopDimensions stop={stop} />
        <StopLoadingRefContact stop={stop} />
        <StopDateAndTime stop={stop} />
        <StopOpeningTime stop={stop} />
        <StopPhone stop={stop} />
        <StopCmrTag stop={stop} />
        {areDocumentsEnabled && <StopDocuments stop={stop} />}
      </div>
    </div>
  );
};

export default DriverCreatedCargoStop;
