import { useEffect } from "react";
import * as actions from "./index";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import * as selectors from "./selectors";

export const useClients = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.loadClients());
  }, [dispatch]);
  return useAppSelector(selectors.selectClients);
};
