import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

const prefix = "app/driver-location";

type Coords = { lat: number; lon: number };

type State = {
  location: { coords: Coords } | null;
  timestamp: DateTime | null;
};

const initialState: State = { location: null, timestamp: null };

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    updateDriverLocation(state, action: PayloadAction<Coords>) {
      state.location = { coords: action.payload };
      state.timestamp = DateTime.utc();
    },
  },
});

export const actions = slice.actions;

export default slice.reducer;
