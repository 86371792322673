import { decodeGoogleAddressComponents } from "dora-contracts";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { formatValidationErrors } from "io-ts-reporters";
import { apiFetch } from "./apiRequest";
import { isMobile } from "./mobile";

const geocodeOkResponse = t.strict({
  status: t.literal("OK"),
  results: t.array(
    t.strict({
      place_id: t.string,
      address_components: t.any,
      formatted_address: t.union([t.string, t.undefined, t.null]),
      geometry: t.type({
        location: t.strict({ lat: t.number, lng: t.number }),
      }),
    })
  ),
});

const googleZeroResultsResonse = t.strict({
  status: t.literal("ZERO_RESULTS"),
});

const geocodeResponse = t.union([geocodeOkResponse, googleZeroResultsResonse]);

export const reverseGeocode = async (coord: { lat: number; lon: number }) => {
  const { lat, lon } = coord;

  const apiKey = "AIzaSyAR9crzIPVA2rrD94G10LhBSURY9-KVuzE";

  const call = isMobile
    ? fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
      )
    : apiFetch(`/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`);

  const result = await call;
  if (!result.ok) {
    throw new Error("Error performing reverse geocode");
  }
  const data = await result
    .json()
    .then(tPromise.decode(geocodeResponse))
    .catch((err) => {
      if (tPromise.isDecodeError(err)) {
        throw new Error(
          `Decode error\n\n${formatValidationErrors(err.errors)}`
        );
      } else {
        throw err;
      }
    });
  switch (data.status) {
    case "ZERO_RESULTS":
      return [];
    case "OK":
      return data.results.map((googleResult) => {
        const addressComponents = decodeGoogleAddressComponents(
          googleResult.address_components
        );
        return {
          googlePlaceId: googleResult.place_id,
          coords: googleCoordToCoord(googleResult.geometry.location),
          formattedAddress: googleResult.formatted_address || null,
          ...addressComponents,
        };
      });
  }
};

const googleCoordToCoord = ({ lat, lng }: { lat: number; lng: number }) => ({
  lat,
  lon: lng,
});
