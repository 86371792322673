import { DateTime } from "luxon";
import { configureStore, createSlice } from "@reduxjs/toolkit";

interface State {
  nowUtc: number;
}

const initialState: State = {
  nowUtc: DateTime.utc().toMillis(),
};

const slice = createSlice({
  name: "app/time",
  initialState,
  reducers: {
    updateNowUtc(state) {
      state.nowUtc = DateTime.utc().toMillis();
    },
  },
});

const store = configureStore({
  reducer: {
    time: slice.reducer,
  },
});

// setInterval(() => {
//   store.dispatch(slice.actions.updateNowUtc());
// }, 1000);

export default slice.reducer;
