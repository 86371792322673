import { createContext, ReactNode, useContext } from "react";
import { loadRouteWithStops } from "../../ducks/data/route-with-stops";
import { useParams } from "react-router";
import { useAppDispatch } from "../../redux-store";

type RoutePageContextType = {
  reloadData: () => void;
};

const RoutePageContext = createContext<RoutePageContextType | undefined>(
  undefined
);

export const RoutePageContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { routeId } = useParams();

  const dispatch = useAppDispatch();

  const reloadData = () => {
    dispatch(loadRouteWithStops(routeId as string));
  };

  return (
    <RoutePageContext.Provider value={{ reloadData }}>
      {children}
    </RoutePageContext.Provider>
  );
};

export const useRoutePageContext = () => {
  const context = useContext(RoutePageContext);
  if (context === undefined) {
    throw new Error(
      "useRoutePageContext must be used within a RoutePageContextProvider"
    );
  }
  return context;
};
