import { isMobile } from "./mobile";
import { ACTIVE_ORG_REF_LOCAL_STORAGE_KEY } from "./organizationStorage";

const apiUrl = (window as any).env?.apiUrl;
const apiRoot = apiUrl ? new URL(apiUrl) : "";

let organization: string | undefined =
  localStorage.getItem(ACTIVE_ORG_REF_LOCAL_STORAGE_KEY) ?? undefined;

export const setOrganizationRef = (ref: string | undefined) => {
  organization = ref;
  if (ref) {
    localStorage.setItem(ACTIVE_ORG_REF_LOCAL_STORAGE_KEY, ref);
  } else {
    localStorage.removeItem(ACTIVE_ORG_REF_LOCAL_STORAGE_KEY);
  }
};

const getRoot = () => {
  if (!apiRoot) {
    throw new Error("VITE_API_ROOT must be set on mobile");
  }
  return apiRoot.origin;
};

export const apiFetch = async (
  input: NodeJS.fetch.RequestInfo,
  init?: RequestInit
) => {
  const hasQuery = (input as string).includes("?");
  const url = isMobile
    ? `${getRoot()}/native-app${input}${hasQuery ? `&loginOrgRef=${organization}` : `?loginOrgRef=${organization}`}`
    : input;
  return fetch(url, init);
};
