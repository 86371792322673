import { useState } from "react";
import AddCubicMetersModal from "./AddCubicMetersModal";
import { Stop } from "../../../ducks/app/session/types";
import { useSelector } from "react-redux";
import { selectShouldShowCubicMetersButtonNew } from "../../../ducks/data/route-with-stops/selectors";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";

const AddCubicMetersModalAndButton = (input: {
  sessionId: string;
  stop: Stop;
  volumeAdded: boolean;
}) => {
  const { stop, sessionId, volumeAdded } = input;
  const [showAddVolumeDialog, setShowAddVolumeDialog] = useState(false);
  const { t } = useTranslation(["stopPage"]);
  const cubicMetersEnabled = useSelector(
    selectShouldShowCubicMetersButtonNew(stop.id)
  );

  return (
    <>
      {showAddVolumeDialog && (
        <AddCubicMetersModal
          sessionId={sessionId!}
          stop={stop}
          onClose={() => setShowAddVolumeDialog(false)}
        />
      )}
      {cubicMetersEnabled && (
        <Button
          onClick={() => setShowAddVolumeDialog(true)}
          variant="outlined"
          size="large"
          startIcon={<ViewInArOutlinedIcon />}
        >
          {t("stopPage:addCubicMeters")}
          {volumeAdded && <CheckIcon />}
        </Button>
      )}
    </>
  );
};

export default AddCubicMetersModalAndButton;
