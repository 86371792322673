import { StopNew } from "../../../ducks/data/route-with-stops/types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import "./stop-component.scss";

const StopOpeningTime = ({ stop }: { stop: StopNew }) => {
  const { t } = useTranslation(["translation"]);

  if (!stop.openingTime) {
    return null;
  }

  return (
    <>
      <div className="stop-component">
        <AccessTimeIcon className="stop-component-icon" />
        <div className="flex-grow">
          <>{stop.openingTime && ` ${stop.openingTime}`}</>
          <>
            {stop.openingTime && stop.fixTime && ` (${t("translation:Fix")})`}
          </>
        </div>
      </div>
    </>
  );
};

export default StopOpeningTime;
