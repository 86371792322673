import { combineReducers } from "@reduxjs/toolkit";
import routeLogin from "./route-login";
import session from "./session";
import connection from "./connection";
import addCargo from "./add-cargo";
import navigation from "./navigation";
import version from "./version";
import driverLocation from "./driver-location";
import apiKeys from "./api-keys";
import time from "./time";

export default combineReducers({
  routeLogin,
  session,
  connection,
  addCargo,
  navigation,
  version,
  driverLocation,
  apiKeys,
  time,
});
