import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Decimal from "decimal.js-light";
import { updateCargoStop } from "../../../../ducks/app/update-cargo";
import { useAppDispatch } from "../../../../redux-store";

type EditLoadmetersDialogProps = {
  cargoId: string;
  stopId: string;
  onClose: () => void;
  loadmeters: string;
};

const EditLoadmetersDialog = (props: EditLoadmetersDialogProps) => {
  const { cargoId, stopId, onClose } = props;
  const { t } = useTranslation("stopPage");

  const [isSaving, setIsSaving] = useState(false);
  const [lm, setLm] = useState<string>(props.loadmeters);
  const dispatch = useAppDispatch();

  const onSaveButtonClick = async () => {
    try {
      setIsSaving(true);
      await dispatch(
        updateCargoStop({ cargoId, stopId, data: { lm: new Decimal(lm) } })
      );
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  return (
    <Dialog fullWidth open onClose={() => !isSaving && onClose()}>
      <DialogTitle>{t("editDialogs.loadmetersTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          type="number"
          required
          InputProps={{
            componentsProps: {
              input: {
                inputMode: "decimal",
              },
            },
          }}
          value={lm}
          onChange={(e) => setLm(e.target.value)}
        />
      </DialogContent>
      <DialogActions className="mx-4 mb-2">
        <Button
          disabled={isSaving}
          onClick={onClose}
          variant="outlined"
          size="large"
          fullWidth
        >
          {t("editDialogs.cancelButton")}
        </Button>
        <Button
          variant="contained"
          fullWidth
          size="large"
          disabled={isSaving || props.loadmeters === lm}
          onClick={onSaveButtonClick}
        >
          {t("editDialogs.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLoadmetersDialog;
