import { useMemo, useState } from "react";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useClients } from "../../ducks/data/clients/hooks";
import useFeature from "../../hooks/useFeature";
import { useTranslation } from "react-i18next";

type ClientSelectorProps = {
  selectedClientId: string | null;
  onClientChange: (clientId: string | null) => void;
};

const ClientSelector = (props: ClientSelectorProps) => {
  const { t } = useTranslation("translation");
  const { onClientChange, selectedClientId } = props;
  const [clientInput, setClientInput] = useState("");

  const autoSelect = useFeature("mobile-auto-select-customer");

  const clients = useClients();

  const mappedClients = useMemo(
    () => (clients || []).map(({ id, name }) => ({ id, label: name })),
    [clients]
  );

  const selectedClient = useMemo(() => {
    return mappedClients.find((x) => x.id === selectedClientId) || null;
  }, [selectedClientId, mappedClients]);

  const options = useMemo(() => {
    const clientInputLower = clientInput.toLowerCase();
    return [...mappedClients].sort((a, b) => {
      const aStartsWith = a.label.toLowerCase().startsWith(clientInputLower);
      const bStartsWith = b.label.toLowerCase().startsWith(clientInputLower);
      if (aStartsWith && !bStartsWith) {
        return -1;
      }
      if (!aStartsWith && bStartsWith) {
        return 1;
      }
      return a.label.localeCompare(b.label);
    });
  }, [mappedClients, clientInput]);

  if (!options) {
    return null;
  }

  return (
    <Autocomplete
      options={options}
      value={selectedClient}
      onInputChange={(_e, v) => setClientInput(v)}
      onChange={(_e, v) => onClientChange(v?.id || null)}
      fullWidth
      autoSelect={autoSelect}
      autoHighlight={autoSelect}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} margin="dense" label={t("client")} />
      )}
    />
  );
};

export default ClientSelector;
