import Dialog from "@mui/material/Dialog";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { CircularProgress, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import "./WorktimePage.scss";
import { useAppDispatch } from "../../redux-store";
import { startTrip, stopTrip } from "../../ducks/app/session";
import { useParams } from "react-router";
import debounce from "lodash/debounce";
import { loadRouteWithStops } from "../../ducks/data/route-with-stops";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useTranslation } from "react-i18next";
import { RouteStartedEndedData } from "../../ducks/data/route-with-stops/types";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { drivingUrl } from "../../routes";
import { useSelector } from "react-redux";
import { selectCurrentTruckId } from "../../ducks/data/route-with-stops/selectors";

const StartEndDayDialog = ({
  onClose,
  data,
  minDate,
  type,
}: {
  onClose: () => void;
  data: RouteStartedEndedData | null;
  minDate?: string;
  type: "START" | "END";
}) => {
  const { t } = useTranslation(["translation"]);
  const params = useParams();
  const sessionId = params.sessionId as string;
  const routeId = params.routeId as string;
  const [date, setDate] = useState<DateTime | null>(
    data ? DateTime.fromISO(data?.dateTime) : null
  );
  const [note, setNote] = useState<string>(data?.note || "");
  const [odometer, setOdometer] = useState<string>(data?.odometer || "");
  const [loading, setLoading] = useState(false);
  const truckId = useSelector(selectCurrentTruckId);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onConfirmPress = useMemo(
    () =>
      debounce(
        () => {
          setLoading(true);
          const action = type === "START" ? startTrip : stopTrip;
          dispatch(
            action({
              odometer: Math.round(+odometer),
              note,
              sessionId,
              dateTime: date,
            })
          )
            .unwrap()
            .then(async () => {
              await new Promise((r) => setTimeout(r, 500));
              dispatch(loadRouteWithStops(routeId));
              onClose();
              if (data === null && type === "START") {
                navigate(
                  drivingUrl({
                    sessionId: sessionId,
                    routeId: routeId,
                    truckId: truckId!,
                  })
                );
              }
            })
            .finally(() => {
              setLoading(false);
            });
        },
        1000,
        { leading: true, trailing: false }
      ),
    [
      setLoading,
      odometer,
      note,
      dispatch,
      onClose,
      sessionId,
      type,
      routeId,
      date,
    ]
  );

  const isPrimaryButtonDisabled = useMemo(() => {
    if (loading) {
      return true;
    }
    return !!(
      minDate &&
      date &&
      DateTime.fromISO(minDate).toMillis() > date.toMillis()
    );
  }, [loading, minDate, date]);

  return (
    <Dialog open onClose={onClose}>
      <div
        className="flex flex-col gap-4"
        style={{ padding: "32px 16px", minWidth: 300 }}
      >
        {date && (
          <>
            <div style={{ fontSize: 18, fontWeight: 500 }}>
              {t("translation:startStopDialog.dialogHeading2")}
            </div>
            <TextField
              fullWidth
              type="date"
              margin="dense"
              label={"Date"}
              value={date.toISODate()}
              onChange={(e) => {
                const eventDate = DateTime.fromISO(e.target.value);
                setDate(
                  date!.set({
                    day: eventDate.day,
                    month: eventDate.month,
                    year: eventDate.year,
                  })
                );
              }}
            />
            <TextField
              type="time"
              autoFocus
              fullWidth
              label={"Time"}
              value={date.toFormat("HH:mm")}
              onChange={(e) => {
                const time = DateTime.fromFormat(e.target.value, "HH:mm");
                setDate(
                  date!.set({
                    hour: time.get("hour"),
                    minute: time.get("minute"),
                  })
                );
              }}
            />
          </>
        )}
        <div style={{ fontSize: 18, fontWeight: 500 }}>
          {t("translation:startStopDialog.dialogHeading")}
        </div>

        <div style={{ fontSize: 14, fontWeight: 400, color: "#666666" }}>
          {type === "START"
            ? t("translation:startStopDialog.dialogContent_START")
            : t("translation:startStopDialog.dialogContent_STOP")}
        </div>

        <TextField
          {...(!date && { autoFocus: true })}
          fullWidth
          type="number"
          label={"Km"}
          value={odometer}
          onChange={(e) => {
            setOdometer(e.target.value);
          }}
        />
        <TextField
          fullWidth
          type="text"
          label={"Note"}
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
        <div>
          <button
            className={
              type === "START"
                ? "time-tracking-button green"
                : "time-tracking-button red"
            }
            disabled={isPrimaryButtonDisabled}
            onClick={() => onConfirmPress()}
          >
            {loading ? (
              <CircularProgress size={22} style={{ color: "white" }} />
            ) : type === "START" ? (
              <PlayCircleOutlineIcon />
            ) : (
              <StopCircleIcon />
            )}
            <div>
              {date
                ? t("translation:submitButtonText")
                : type === "START"
                  ? t("translation:startStopDialog.submitButtonText_START")
                  : t("translation:startStopDialog.submitButtonText_STOP")}
            </div>
          </button>
          <button className="time-tracking-button hollow" onClick={onClose}>
            {t("translation:startStopDialog.cancelButtonText")}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default StartEndDayDialog;
