import { createDriverRoutesTypes, isoDateTime } from "dora-contracts";
import * as t from "io-ts";

const {
  driverRoutesResponseT,
  driverRouteWaypointStopT,
  driverRouteCargoStopT,
  routeStartedEndedDataT,
  cargoT,
  stopT,
} = createDriverRoutesTypes({
  decimal: t.string,
  date: t.string,
  dateTime: t.string,
});

export { driverRoutesResponseT };

export type CargoNew = t.TypeOf<typeof cargoT>;
export type RouteWaypointStopNew = t.TypeOf<typeof driverRouteWaypointStopT>;
export type RouteCargoStopNew = t.TypeOf<typeof driverRouteCargoStopT>;
export type StopNew = t.TypeOf<typeof stopT>;
export type DriverRoutesResponse = t.TypeOf<typeof driverRoutesResponseT>;
export type RouteStartedEndedData = t.TypeOf<typeof routeStartedEndedDataT>;
