import { createDriverUpdateCargoTypes, decimal } from "dora-contracts";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { noContentDecoder } from "../../api";
import * as t from "io-ts";
import { reloadCurrentSession } from "../session";
import { getPosition } from "../../../helpers/position";

const { updateCargoStopT, updateCargoStopDataT } =
  createDriverUpdateCargoTypes({ decimal });

type UpdateCargoStopData = t.TypeOf<typeof updateCargoStopDataT>;

export const updateCargoStop = createAsyncThunk(
  "app/update-cargo-stop",
  async (
    input: {
      cargoId: string;
      stopId: string;
      data: UpdateCargoStopData;
    },
    thunkApi
  ) => {
    const position = await getPosition();
    return api(thunkApi)
      .put({
        url: `/drivers-api/cargos/${input.cargoId}/stops/${input.stopId}`,
        body: updateCargoStopT.encode({
          data: input.data,
          position: position
            ? {
                lat: position?.lat,
                lon: position?.lon,
                accuracy: position?.accuracy,
                place: null,
              }
            : null,
        }),
        decoder: noContentDecoder,
      })
      .then(() => {
        thunkApi.dispatch(reloadCurrentSession());
      });
  }
);
