import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "../../redux-store";
import { FeatureName } from "dora-contracts";
import mapValues from "lodash/mapValues";

const prefix = "features";

type Feature = { enabled: boolean };
type State = Partial<Record<FeatureName, Feature>>;

const initialState: State = {};

export const initialize = (): AppThunkAction => async (dispatch) => {
  // TODO: driver api?
  const res = await fetch("/drivers-api/features");
  const data = await res.json();
  if (typeof data !== "object") return;
  dispatch(
    slice.actions.setFeatures(
      mapValues(data, (v) => ({
        enabled: !!v.enabled,
      }))
    )
  );
};

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    setFeatures: (_, action: PayloadAction<State>) => {
      return action.payload;
    },
  },
});

export default slice.reducer;
