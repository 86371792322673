import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux-store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { logout, logoutSession } from "../ducks/auth";
import { selectMe } from "../ducks/auth/selectors";
import { MenuBookOutlined, SportsScore } from "@mui/icons-material";
import useFeature from "../hooks/useFeature";
import { isMobile } from "../helpers/mobile";

const drawerWidth = 240;

export default function DrawerAppBar(props: any) {
  const { handleDrawerToggle, mobileOpen, darkMode, setDarkMode } = props;
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const me = useSelector(selectMe);

  const isNotebookEnabled = useFeature("driver-notebook");
  const isNewPageEnabled = useFeature("new-mobile-stops-page");

  const logOut = async () => {
    await dispatch(logout());
    if (isMobile) {
      navigate("/routes/login");
    } else if (me) {
      window.location.href = me.driverLoginUrl;
    }
  };

  const handleModeChange = () => {
    setDarkMode(!darkMode);
  };

  const onViewNotebookClick = () => {
    navigate("/driver-notebook");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Typography variant="h6" sx={{ my: 1.5, textAlign: "center" }}>
        DORA
      </Typography>
      <Divider />
      <List
      // subheader={
      //     <ListSubheader>
      //         {t("drawer.menuHeading_OTHER")}
      //     </ListSubheader>
      // }
      >
        <ListItem>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AltRouteOutlinedIcon />}
            onClick={() => {
              dispatch(logoutSession()).then(() => {
                navigate(`/routes/login`);
              });
            }}
          >
            {t("drawer.menuItem_CHANGETRIP")}
          </Button>
        </ListItem>

        <ListItem>
          <Button
            variant="contained"
            fullWidth
            startIcon={<SportsScore />}
            onClick={() => {
              dispatch(logoutSession()).then(() => {
                navigate(`/routes/login-completed-trips`);
              });
            }}
          >
            {t("drawer.menuItem_COMPLETEDTRIPS")}
          </Button>
        </ListItem>

        {!isNewPageEnabled && (
          <ListItem onClick={handleModeChange}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DarkModeOutlinedIcon />}
            >
              {t("drawer.menuItem_DARKMODE")}
            </Button>
          </ListItem>
        )}

        {isNotebookEnabled && (
          <ListItem onClick={onViewNotebookClick}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<MenuBookOutlined />}
            >
              {t("drawer.menuItem_NOTEBOOK")}
            </Button>
          </ListItem>
        )}

        <ListItem onClick={logOut}>
          <Button variant="contained" fullWidth startIcon={<LogoutIcon />}>
            {t("drawer.menuItem_LOGOUT")}
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Box component="nav">
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
