import React from "react";
import { useTranslation } from "react-i18next";

interface Size {
  height?: string | null;
  width?: string | null;
  length?: string | null;
}

const CargoDimensions = ({
  size,
  type,
}: {
  size: Size;
  type: "PICKUP" | "DROPOFF";
}) => {
  let { t } = useTranslation();
  let help: (string | React.ReactNode)[] = [];
  let val: (string | React.ReactNode)[] = [];
  let addHelp = (key: string) => {
    if (help.length) {
      help.push("×");
    }
    help.push(key);
  };
  let addVal = (x: string) => {
    if (val.length) {
      val.push("×");
    }
    val.push(x);
  };
  if (size.length && size.length !== "0") {
    addHelp(t("dimensions.abbrev.length"));
    addVal(size.length.toString());
  }
  if (size.width && size.width !== "0") {
    addHelp(t("dimensions.abbrev.width"));
    addVal(size.width.toString());
  }
  if (size.height && size.height !== "0") {
    addHelp(t("dimensions.abbrev.height"));
    addVal(size.height.toString());
  }
  return help.length ? (
    <span aria-label={t("dimensions.label", { context: type })}>
      {val.join("")} cm. ({help.join("")})
    </span>
  ) : null;
};

export default CargoDimensions;
