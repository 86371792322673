import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api, { createDecoder } from "../../api";
import * as t from "io-ts";

type State = {
  hideLoadmeters: boolean;
};

const prefix = "data/driver-app-settings";

const initialState: State = {
  hideLoadmeters: true,
};

export const loadHideLoadmeterState = createAsyncThunk(
  `${prefix}/load-hide-loadmeter-state`,
  async (_: void, thunkApi) => {
    return await api(thunkApi).get({
      url: "/drivers-api/driver-app-settings/hide-loadmeter-state",
      decoder: createDecoder(t.strict({ value: t.boolean })),
    });
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadHideLoadmeterState.fulfilled, (state, action) => {
      state.hideLoadmeters = action.payload.value;
    });
  },
});

export default slice.reducer;
