import { useCallback } from "react";
import { AutoSuggestItem } from "dora-shared";
import { useAppDispatch } from "../../../redux-store";
import { actions } from "../../../ducks/app/driver-location";
import { reverseGeoCodeMany } from "../../../helpers/google-helpers";
import { getPosition } from "../../../helpers/position";
import { usePlacePredictions } from "../../../places-context";

export const usePlaceSearch = () => {
  const { suggest } = usePlacePredictions();
  return suggest;
};

export const useGetLocations = () => {
  const dispatch = useAppDispatch();
  return useCallback(async (): Promise<AutoSuggestItem[]> => {
    const loc = await getPosition();
    if (!loc) {
      return [];
    }
    dispatch(actions.updateDriverLocation(loc));
    return reverseGeoCodeMany(loc);
  }, [dispatch]);
};
