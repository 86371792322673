import { StopNew } from "../../../ducks/data/route-with-stops/types";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import "./stop-component.scss";

const StopPhone = ({ stop }: { stop: StopNew }) => {
  if (!stop.phone) {
    return null;
  }

  return (
    <div>
      <a href={`tel:${stop.phone}`} rel="noreferrer" className="stop-component">
        <PhoneInTalkOutlinedIcon className="stop-component-icon" />
        <div className="flex-grow">{stop.phone}</div>
      </a>
    </div>
  );
};

export default StopPhone;
