import { useSelector } from "react-redux";
import {
  selectCompletedStopsCreatedByDispatcherFilteredByShowHideButton,
  selectUpcomingStopsCreatedByDispatcher,
} from "../../../ducks/data/route-with-stops/selectors";
import {
  RouteCargoStopNew,
  RouteWaypointStopNew,
} from "../../../ducks/data/route-with-stops/types";
import DispatcherCreatedWaypointStop from "./DispatcherCreatedWaypointStop";
import DispatcherCreatedCargoStop from "./DispatcherCreatedCargoStop";

const DispatcherCreatedStops = () => {
  const completedStops = useSelector(
    selectCompletedStopsCreatedByDispatcherFilteredByShowHideButton
  );
  const upcomingStops = useSelector(selectUpcomingStopsCreatedByDispatcher);

  return (
    <>
      {completedStops?.map((completedStop, i) => (
        <DispatcherCreatedStop key={i} routeStop={completedStop} />
      ))}
      {upcomingStops?.map((upcomingStop, i) => (
        <DispatcherCreatedStop key={i} routeStop={upcomingStop} />
      ))}
    </>
  );
};

const DispatcherCreatedStop = ({
  routeStop,
}: {
  routeStop: RouteWaypointStopNew | RouteCargoStopNew;
}) => {
  if (routeStop.type === "WAYPOINT") {
    return <DispatcherCreatedWaypointStop waypointStop={routeStop} />;
  }
  if (routeStop.type === "CARGO_STOP") {
    return <DispatcherCreatedCargoStop cargoStop={routeStop} />;
  }
  return null;
};

export default DispatcherCreatedStops;
