import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

export const selectStatus = (state: RootState) => state.app.routeLogin.status;

export const selectTrailers = (state: RootState) =>
  state.app.routeLogin.trailers;

const selectRoutes = (state: RootState) => state.app.routeLogin.routes;

export const selectNotEndedRoutes = createSelector(selectRoutes, (routes) =>
  routes.filter((route) => !route.completed)
);

export const selectEndedRoutes = createSelector(selectRoutes, (routes) =>
  routes.filter((route) => route.completed)
);

// export const selectCurrentSessionId = (state: RootState) =>
// state.app.session.currentSessionId;
