export const SEEN_ORG_REFS_LOCAL_STORAGE_KEY = "seenOrganizations";
export const ACTIVE_ORG_REF_LOCAL_STORAGE_KEY = "activeOrganization";

export type Organization = {
  name: string;
  reference: string;
};

export const addOrganization = (org: Organization) => {
  const seenOrganizations = localStorage.getItem(
    SEEN_ORG_REFS_LOCAL_STORAGE_KEY
  );
  let organizations = seenOrganizations ? JSON.parse(seenOrganizations) : [];
  const existingOrg = organizations.find(
    (existingOrg: Organization) => existingOrg.reference === org.reference
  );

  if (existingOrg) {
    // Remove from list
    organizations = organizations.filter(
      (existingOrg: Organization) => existingOrg.reference !== org.reference
    );
  }

  // Push to front to keep most recent at the top
  organizations.unshift(org);
  localStorage.setItem(
    SEEN_ORG_REFS_LOCAL_STORAGE_KEY,
    JSON.stringify(organizations)
  );
};

export const getOrganizations = (): Organization[] => {
  const seenOrganizations = localStorage.getItem(
    SEEN_ORG_REFS_LOCAL_STORAGE_KEY
  );
  return seenOrganizations ? JSON.parse(seenOrganizations) : [];
};
