import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { selectMe } from "../../ducks/auth/selectors";
import { selectRouteWithStopsData } from "../../ducks/data/route-with-stops/selectors";
import StartEndDayDialog from "./StartEndDayDialog";
import "./WorktimePage.scss";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import clsx from "clsx";
import { DateTime } from "luxon";
import { selectNowMillisUtc } from "../../ducks/app/time/selectors";
import withAuth from "../../components/withAuth";
import { useAppDispatch } from "../../redux-store";
import { loadRouteWithStops } from "../../ducks/data/route-with-stops";
import { useParams } from "react-router";
import { loadSession } from "../../ducks/app/session";

const WorktimePage = () => {
  const { t } = useTranslation(["translation"]);
  const params = useParams();
  const paramsRouteId = params.routeId as string;
  const paramsSessionId = params.sessionId as string;
  const me = useSelector(selectMe);
  const routeData = useSelector(selectRouteWithStopsData);
  const [shouldShowStartDayDialog, setShouldShowStartDayDialog] =
    useState(false);
  const [shouldShowEndDayDialog, setShouldShowEndDayDialog] = useState(false);
  const dispatch = useAppDispatch();

  const now = useSelector(selectNowMillisUtc);

  useEffect(() => {
    dispatch(loadRouteWithStops(paramsRouteId));
    dispatch(loadSession(paramsSessionId));
  }, [dispatch, paramsRouteId, paramsSessionId]);

  if (!me || !routeData) {
    return null;
  }

  const isStopButtonDisabled =
    !routeData.started ||
    now < DateTime.fromISO(routeData!.started.dateTime).toMillis();

  const startButtonClasses = clsx("time-tracking-button green", {
    edit: routeData.started,
  });
  const stopButtonClasses = clsx("time-tracking-button red", {
    edit: routeData.stopped,
  });

  return (
    <div className="worktime-page">
      {shouldShowStartDayDialog && (
        <StartEndDayDialog
          data={routeData.started}
          onClose={() => setShouldShowStartDayDialog(false)}
          type={"START"}
        />
      )}
      {shouldShowEndDayDialog && (
        <StartEndDayDialog
          data={routeData.stopped}
          minDate={routeData.started?.dateTime}
          onClose={() => setShouldShowEndDayDialog(false)}
          type={"END"}
        />
      )}
      <div>
        {t("translation:startStopDialog.loggedInAs", {
          driverName: `${me.firstName} ${me.lastName}`,
        })}
      </div>
      <div className="flex flex-col gap-2 mt-12">
        <button
          className={startButtonClasses}
          onClick={() => setShouldShowStartDayDialog(true)}
        >
          {routeData.started ? (
            <>
              <div>
                <div>
                  {t("translation:startStopDialog.submitButtonText_START")}
                </div>
                <div className="font-normal">
                  {DateTime.fromISO(routeData.started.dateTime).toFormat(
                    "dd.MM.yyyy HH:mm"
                  )}
                </div>
              </div>
              <EditOutlinedIcon />
            </>
          ) : (
            <>
              <PlayCircleOutlineIcon />
              <div>
                {t("translation:startStopDialog.submitButtonText_START")}
              </div>
            </>
          )}
        </button>
        <button
          className={stopButtonClasses}
          disabled={isStopButtonDisabled}
          onClick={() => setShouldShowEndDayDialog(true)}
        >
          {routeData.stopped ? (
            <>
              <div>
                <div>
                  {t("translation:startStopDialog.submitButtonText_STOP")}
                </div>
                <div className="font-normal">
                  {DateTime.fromISO(routeData.stopped.dateTime).toFormat(
                    "dd.MM.yyyy HH:mm"
                  )}
                </div>
              </div>
              <EditOutlinedIcon />
            </>
          ) : (
            <>
              <StopCircleIcon />
              <div>
                {t("translation:startStopDialog.submitButtonText_STOP")}
              </div>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default withAuth(WorktimePage);
