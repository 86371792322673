import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { updateCargoStop } from "../../../../ducks/app/update-cargo";
import { useAppDispatch } from "../../../../redux-store";
import ClientSelector from "../../../AddNewCargoPage/ClientSelector";

type EditClientDialogProps = {
  cargoId: string;
  stopId: string;
  clientId: string | null;
  onClose: () => void;
};

const EditClientDialog = (props: EditClientDialogProps) => {
  const { onClose, cargoId, stopId } = props;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [client, setClient] = useState<string | null>(props.clientId);

  const onSaveButtonClick = async () => {
    try {
      setIsSaving(true);
      await dispatch(
        updateCargoStop({ cargoId, stopId, data: { clientId: client } })
      );
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  return (
    <Dialog fullWidth open onClose={() => !isSaving && onClose()}>
      <DialogTitle>{t("editDialogs.clientTitle")}</DialogTitle>
      <div style={{ paddingTop: 0 }}>
        {/* div used for overwriting a global style for dialog contents*/}
      </div>
      <DialogContent>
        <ClientSelector
          selectedClientId={client}
          onClientChange={(clientId) => setClient(clientId)}
        />
      </DialogContent>
      <DialogActions className="mx-4 mb-2">
        <Button
          disabled={isSaving}
          onClick={onClose}
          variant="outlined"
          size="large"
          fullWidth
        >
          {t("editDialogs.cancelButton")}
        </Button>
        <Button
          disabled={isSaving || props.clientId === client}
          onClick={onSaveButtonClick}
          variant="contained"
          fullWidth
          size="large"
        >
          {t("editDialogs.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClientDialog;
