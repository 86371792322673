import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { selectCargo } from "../../../ducks/app/session/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditCargoTypeDialog from "./edit-dialogs/EditCargoTypeDialog";
import { useState } from "react";
import EditDescriptionDialog from "./edit-dialogs/EditDescriptionDialog";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const CargoInfo = ({
  cargoId,
  stopId,
  shouldShowDriverEditButtons,
}: {
  cargoId: string;
  stopId: string | null; // only provide stop id if component is used in a stop and allows editing values
  shouldShowDriverEditButtons?: boolean;
}) => {
  const { t } = useTranslation(["translation", "stopPage", "types"]);
  const [shouldShowEditCargoTypeDialog, setShouldShowEditCargoTypeDialog] =
    useState(false);
  const [shouldShowEditDescriptionDialog, setShouldShowEditDescriptionDialog] =
    useState(false);

  const cargo = useSelector(selectCargo(cargoId));
  if (!cargo) {
    return null;
  }
  return (
    <Stack>
      {shouldShowEditCargoTypeDialog && (
        <EditCargoTypeDialog
          cargoId={cargoId}
          stopId={stopId!}
          type={cargo.type}
          onClose={() => setShouldShowEditCargoTypeDialog(false)}
        />
      )}
      {shouldShowEditDescriptionDialog && (
        <EditDescriptionDialog
          cargoId={cargoId}
          stopId={stopId!}
          desc={cargo.description}
          onClose={() => setShouldShowEditDescriptionDialog(false)}
        />
      )}
      <Alert
        icon={false}
        severity="info"
        sx={{ mt: 2, paddingRight: 0, ".MuiAlert-message": { width: "100%" } }}
      >
        <Typography variant="subtitle1">
          <div className="flex items-center justify-between">
            <div className="flex-grow">
              {t(`stopPage:cargoType`)}
              <span>:&nbsp;</span>
              <span>
                {cargo.type ? t(`types:type`, { context: cargo.type }) : ""}
              </span>
            </div>
            {shouldShowDriverEditButtons && (
              <Button onClick={() => setShouldShowEditCargoTypeDialog(true)}>
                {/* {t("stopPage:editButton")} */}
                <EditOutlinedIcon />
              </Button>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex-grow">
              {t(`stopPage:description`)}
              <span>:&nbsp;</span>
              <span>{cargo.description || ""}</span>
            </div>
            {shouldShowDriverEditButtons && (
              <Button onClick={() => setShouldShowEditDescriptionDialog(true)}>
                {/* {t("stopPage:editButton")} */}
                <EditOutlinedIcon />
              </Button>
            )}
          </div>

          <br />
          {cargo.size && (
            <span>
              {t("translation:cargoSize", {
                context: cargo.size.unitId,
                count: +cargo.size.quantity,
              })}
            </span>
          )}
        </Typography>
      </Alert>
    </Stack>
  );
};

export default CargoInfo;
