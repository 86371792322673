import * as connectionActions from "./app/connection";
import { AnyAction, ThunkDispatch, nanoid } from "@reduxjs/toolkit";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { clearState } from "./auth";
import { apiFetch } from "../helpers/apiRequest";

type FetchDecoder<T> = (result: Response) => Promise<T>;

// Initialized when the application is loaded. Whenever the user refreshes the
// browser, this will have a new value. This allows us to track what happened
// in the scope of a single browser instance.
export const browserSessionId = nanoid();

export const createDecoder = <T extends t.Type<any, any, any>>(
  codec: T
): FetchDecoder<t.TypeOf<T>> => {
  const decoder = tPromise.decode(codec);
  return async (response: Response) => {
    if (!response.ok) {
      throw new Error("Bad response from server");
    }
    const json = await response.json();
    return await decoder(json).catch((err) => {
      throw err;
    });
  };
};

export const rawDecoder: FetchDecoder<unknown> = async (response: Response) => {
  if (!response.ok) {
    throw new Error("Bad response from server");
  }
  return await response.json();
};

export const noContentDecoder: FetchDecoder<void> = async (
  response: Response
) => {
  if (!response.ok) {
    throw new Error("Bad response from server");
  }
  return;
};

const postPutMethod =
  (dispatch: ThunkDispatch<any, unknown, AnyAction>, method: "POST" | "PUT") =>
  async <T extends unknown = unknown>({
    url,
    body,
    decoder,
  }: {
    url: string;
    body: unknown;
    decoder: FetchDecoder<T>;
  }): Promise<T> => {
    try {
      const response = await apiFetch(url, {
        body: JSON.stringify(body),
        method: method,
        headers: { "content-type": "application/json", browserSessionId },
      });
      if (response.status === 401) {
        dispatch(clearState());
      }
      const result = await decoder(response);
      dispatch(connectionActions.errorCleared());
      return result;
    } catch (e) {
      dispatch(connectionActions.errorReturned());
      throw e;
    }
  };

/**
 * Coordinates calling the backend with error communication to global state.
 *
 * If an API call fails, the state will globally update indicating that there
 * is an error in communicating with the server. This allows us to present one
 * nice "technical error" box to the user.
 */
const api = ({
  dispatch,
}: {
  dispatch: ThunkDispatch<any, unknown, AnyAction>; // TODO: Can we get rid of any here?
}) => ({
  get: async <T extends unknown = unknown>({
    url,
    decoder,
  }: {
    url: string;
    decoder: FetchDecoder<T>;
  }): Promise<T> => {
    try {
      const response = await apiFetch(url, { headers: { browserSessionId } });
      if (response.status === 401) {
        dispatch(clearState());
      }
      const result = await decoder(response);
      dispatch(connectionActions.errorCleared());
      return result;
    } catch (e) {
      dispatch(connectionActions.errorReturned());
      throw e;
    }
  },
  post: postPutMethod(dispatch, "POST"),
  put: postPutMethod(dispatch, "PUT"),
  delete: async <T extends unknown = unknown>({
    url,
    decoder,
  }: {
    url: string;
    decoder: FetchDecoder<T>;
  }): Promise<T> => {
    try {
      const response = await apiFetch(url, {
        method: "DELETE",
        headers: {
          browserSessionId,
        },
      });
      if (response.status === 401) {
        dispatch(clearState());
      }
      const result = await decoder(response);
      dispatch(connectionActions.errorCleared());
      return result;
    } catch (e) {
      dispatch(connectionActions.errorReturned());
      throw e;
    }
  },
});

export const reportErrorToBackend = (body: Record<string, unknown>) => {
  apiFetch("/drivers-api/log-entries/errors", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      browserSessionId,
    },
    body: JSON.stringify({ ...body, sessionId: browserSessionId }),
  }).catch(() => {
    // Don't throw error to avoid infinite circular loop
  });
};

export const reportWarningToBackend = (body: Record<string, unknown>) => {
  apiFetch("/drivers-api/log-entries/warnings", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      browserSessionId,
    },
    body: JSON.stringify({ ...body, sessionId: browserSessionId }),
  }).catch(() => {
    // Don't throw error to avoid infinite circular loop
  });
};

export default api;
