import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, AppThunkAction, RootState } from "../../../redux-store";

interface State {
  status: "OK" | "ERROR";
}

const initialState: State = {
  status: "OK",
};

export const errorCleared =
  (): AppThunkAction => (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().app.connection.status !== "OK") {
      dispatch(slice.actions.errorCleared());
    }
  };

export const errorReturned =
  (): AppThunkAction => (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().app.connection.status === "OK") {
      dispatch(slice.actions.errorReturned());
    }
  };

const slice = createSlice({
  name: "app/server-connection",
  initialState,
  reducers: {
    errorReturned: (state) => {
      state.status = "ERROR";
    },
    errorCleared: (state) => {
      state.status = "OK";
    },
  },
});

export default slice.reducer;
