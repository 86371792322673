import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const prefix = "app/navigation";

type State = {
  currentSessionId: string | null;
};

const initialState: State = {
  currentSessionId: null,
};

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setCurrentSessionId(state, action: PayloadAction<string | undefined>) {
      if (action.payload) {
        state.currentSessionId = action.payload;
      }
    },
    clearCurrentSessionId(state) {
      state.currentSessionId = null;
    },
  },
});

export default slice.reducer;
export const { setCurrentSessionId, clearCurrentSessionId } = slice.actions;
