import { Place, Apartment, Star } from "@mui/icons-material";
import "./SearchResultOption.scss";
import Typography from "@mui/material/Typography";
import RenderParsedText from "../../../components/location-search-result/RenderParsedText";
import { SearchOption } from "./SearchOption";

const SearchResultOption = ({ option }: { option: SearchOption }) => {
  const { type, value } = option;
  let component;

  switch (type) {
    default:
      switch (value.locationType) {
        case "TERMINAL":
          component = (
            <div className="search-result-option__wrapper">
              <Apartment className="search-result-option__icon" />
              <div className="search-result-option__text">
                {value.data.customName}
              </div>
            </div>
          );
          break;
        case "FAVORITE":
          component = (
            <div className="search-result-option__wrapper">
              <Star className="search-result-option__icon" />
              <div className="search-result-option__text">
                {value.data.customName}
              </div>
            </div>
          );
          break;
        case "REVERSE_GEOCODE_OPTION":
          component = (
            <div className="search-result-option__wrapper">
              <Place className="search-result-option__icon" />
              <div className="search-result-option__text">
                <div>{value.data.label}</div>
                <Typography
                  component="div"
                  variant="body2"
                  color="text.secondary"
                >
                  <RenderParsedText item={value.data.address} secondary />
                </Typography>
              </div>
            </div>
          );
          break;
        case "SEARCH_RESULT":
          const data = value.data;
          component = (
            <div className="search-result-option__wrapper">
              <Place className="search-result-option__icon" />
              <div className="search-result-option__text">
                <div>
                  <RenderParsedText item={data.title} />
                </div>
                <Typography
                  component="div"
                  variant="body2"
                  color="text.secondary"
                >
                  <RenderParsedText item={data.address} secondary />
                </Typography>
              </div>
            </div>
          );
          break;
      }
  }

  return <div className="search-result-option__container">{component}</div>;
};

export default SearchResultOption;
