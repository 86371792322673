import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Settings } from "luxon";
import { initReactI18next } from "react-i18next";
import da from "./da";
import en from "./en";
import de from "./de";
import ro from "./ro";
import ru from "./ru";
import it from "./it";
import ee from "./ee";

export const Languages = {
  da: "Dansk",
  en: "English",
  de: "Deutsch",
  ro: "Românesc",
  ru: "Русский",
  it: "Italiano",
  ee: "Eesti",
} as const;

const supportedLanguages = Object.keys(Languages);
export type LanguageType = keyof typeof Languages;

// With this syntax, only translations defined in all resources can be used,
// thus the compiler will tell if translations are missing
type CoreResources = typeof da;

declare module "react-i18next" {
  interface Resources {
    translation: CoreResources["translation"];
    stopPage: CoreResources["stopPage"];
    authPage: CoreResources["authPage"];
    addDropoffPage: CoreResources["addDropoffPage"];
    types: CoreResources["types"];
  }
}

const i18nResources: Record<LanguageType, any> = {
  da,
  en,
  de,
  ro,
  ru,
  it,
  ee,
};

const setDateLanguage = (language: string) => {
  // Luxon
  Settings.defaultLocale = i18n.language;
};

export type i18nTranslationKey = any;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: "da",
      defaultNS: "translation",
      fallbackLng: "da",
      resources: i18nResources,
      nsSeparator: ":",
      interpolation: {
        escapeValue: false,
      },
    },
    (err, t) => {
      setDateLanguage(i18n.language);
    }
  );

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "translation";
    resources: CoreResources;
  }
}

export const changeLanguage = async (newLanguage: keyof typeof Languages) => {
  const language = supportedLanguages.includes(newLanguage)
    ? newLanguage
    : "da";
  await i18n.changeLanguage(language);
  setDateLanguage(language);
};
